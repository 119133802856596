.contact {
	font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;

	/*
	.navbar {
		top: -18px;

		.navbar-brand {
			img {
				width: 280px;
				height: auto;

				@media screen and (max-width: 440px) {
					width: 200px;
				}
				@media screen and (max-width: 275px) {
					width: 150px;
				}
			}
		}

	}
	header.sticky  {
		.navbar-brand img {
		    padding-top: 28px;
		}
		.navbar-nav {
			margin-top: 10px;
		}
	}

	@media screen and (min-width: 992px) {
		header .navbar-nav > li {
		    margin-top: 35px;
		}
	}
	*/

	.hero {
		background-image: url('../../assets/img/contact/nav-bg.png');
		background-position: top center;
		background-size: cover;
		height: 190px;

		.middle {
			h2 {
				margin-top: 80px;
			}
		}
		@media screen and (max-height: 690px) {
			.middle {
				h2 {
					font-size: 26px;
				}
			}
		}
		@media screen and (max-width: 1500px) {
			.middle {
				h2 {
					font-size: 40px;
				}
			}
		}
		@media screen and (max-width: 944px) {
			.middle {
				h2 {
					font-size: 26px;
				}
			}
		}
	}
	.enquiry-section {
		background-color: #ebebeb;
		text-align: center;

		.title {
			font-size: 30px;
			color: $brand-pink;
			margin-top: 46px;
			margin-bottom: 40px;
		}
		.input-group {
			font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
			width: 50%;
			margin-bottom: 20px;

			select {
				color: #666;
				margin-left: 10px;
				@media screen and (max-width: $screen-sm) {
					margin-left: 0px;
				}
			}
			input, select {
				height: 40px;
			}
			input, select, textarea {
				border-radius: 30px;
				font-size: 18px;
				padding-left: 20px;
				padding-right: 20px;
				width: 95%;
				background-color: white;
				border: 0;

				&:focus {
					border: none;
					box-shadow: none;
					outline: none;
				}
				@media screen and (max-width: $screen-sm) {
					width: 100%;
				}
			}

			input[type='number'] {
				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
				    /* display: none; <- Crashes Chrome on hover */
				    -webkit-appearance: none;
				    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
				}
			}

			&.message-group {
				width: 100%;

				textarea {
					height: 100px;
					resize: none;
					padding: 10px 20px;

					@media screen and (max-width: $screen-md) {
						width: 97%;
						height: 150px;
					}
					@media screen and (max-width: $screen-sm) {
						width: 100%;
					}
				}
			}
			&.submit-group {
				width: 100%;

				button {
					width: 100%;
					height: 40px;
					font-size: 18px;
					text-align: left;
					padding-left: 20px;
					margin-top: 40px;
					margin-bottom: 30px;
					vertical-align: middle;
					touch-action: manipulation;
					cursor: pointer;
					white-space: nowrap;
					padding: 6px 12px;
					line-height: 1.428571429;
					font-family: 'Gibson-SemiBold', sans-serif;
					transition: background-color .8s;
					display: inline-block;
					position: relative;
					background-size: 100%;
					background: -webkit-linear-gradient(left, #a3195b 0%, #961b80 100%);
					background: linear-gradient(to right, #a3195b 0%, #961b80 100%);
					border-radius: 30px;
					border: 0;
					color: white;

					i {
						float: right;
						padding-top: 3px;
						padding-right: 10px;
					}

					@media screen and (max-width: $screen-md) {
						width: 50%;
						margin: 0 auto;
					}
					@media screen and (max-width: 400px) {
						width: 75%;
						margin: 0 auto;
					}
				}
			}

			@media screen and (max-width: $screen-sm) {
				width: 100%;
			}
		}
	}
	.locations {
		.middle-text {
			margin-top: 42px;
			margin-bottom: 32px;
		}
		.cheshire {
			background-image: url('../../assets/img/contact/cheshire-notext.png');
			background-size: cover;
		}
		.liverpool {
			background-image: url('../../assets/img/contact/liverpool-notext.png');
			background-size: cover;
		}
		.manchester {
			background-image: url('../../assets/img/contact/manchester-notext.png');
			background-size: cover;
		}
		.birmingham {
			background-image: url('../../assets/img/contact/birmingham-notext.png');
			background-size: cover;
		}
		.enquiry-link-wrapper {
			.enquiry-link {
				margin-bottom: 30px;
				font-size: 18px;
				&.first {
					margin-bottom: 10px;
				}
			}
			@media screen and (max-width: $screen-md) {
				text-align: center;
			}
		}


		@media screen and (max-width: $screen-md) {
			padding-top: 60px;

			.middle-text {
				width: 75%;
				margin: 10px auto;
				display: block;
			}

			.enquiry-link {
				width: 50%;
				margin-left: auto;
				margin-right: auto;
			}
		}

		@media screen and (max-width: $screen-sm) {
			padding-top: 0;

			.middle-text {
				width: 100%;
			}
		}

		@media screen and (max-width: 500px) {
			.enquiry-link {
				width: 75%;
			}
		}
		@media screen and (max-width: 300px) {
			.enquiry-link {
				font-size: 14px;
				width: 75%;
			}
		}
	}
	.middle-text {
		position: relative;
		background-size: 100% 100%;
		background-position: center;
		width: 100%;
		height: 225px;
		text-align: center;
		transition: background-size .5s ease;

		&:hover {
			@media screen and (min-width: $screen-lg) {
				background-size: cover;
			}
		}
		h3 {
			position: absolute;
			color: rgba(white, 0.8);
			font-size: 40px;
			left: 0;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			margin: 0;
		}
		@media screen and (max-width: $screen-lg) {
			background-size: cover;
		}
	}
	.coming-soon {
		text-align: center;
		background-color: #ebebeb;

		.title {
			font-size: 30px;
			color: $brand-pink;
			margin-top: 35px;
			margin-bottom: 30px;
		}
		.followup {
			font-size: 20px;
			color: #666;
			font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
			margin-bottom: 40px;
		}
		.london {
			background-image: url('../../assets/img/contact/london-notext.png');
			background-size: 100% 100%;
			background-position: center;
			width: 100%;
			height: 225px;
			margin-bottom: 30px;
			transition: background-size .5s ease;

			&:hover {
				@media screen and (min-width: $screen-lg) {
					background-size: 110% 110%;
				}
			}



			@media screen and (max-width: $screen-lg) {
				background-size: cover;
			}
		}

		@media screen and (max-width: $screen-md) {
			.middle-text {
				width: 75%;
				margin: 10px auto;
				display: block;
			}
		}
	}
}
