.single-article {
	font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;

	header {
		@media screen and (min-width: $screen-md) {
			height: 90px
		}
	}
	.navbar {
		top: -16px;

		.navbar-nav {
			margin-top: 4px;
		}
		.navbar-toggle {
			@media screen and (max-width: $screen-md) {
				margin-bottom: 23px;
			}
		}
		.navbar-brand {
			height: 90px;
			img {
				width: 260px;
				height: auto;

				@media screen and (max-width: $screen-sm) {
					width: 200px;
					margin-top: 7px;
				}
				@media screen and (max-width: 275px) {
					width: 150px;
				}
			}

			@media screen and (max-width: $screen-md) {
				height: 100px;
			}
		}
		@media screen and (max-width: $screen-md) {
			min-height: 100px;
		}
	}
	header.sticky  {
		.navbar-nav {
			margin-top: 5px;
		}
	}

	@media screen and (min-width: 992px) {
		header .navbar-nav > li {
		    margin-top: 35px;
		}
	}

	@media screen and (max-width: 380px) {
		header.sticky .navbar-brand img {
		    width: 175px !important;
			padding-top: 30px;
		}
	}

	.hero {
		background-image: url('../../assets/img/insights/nav-bg.png');
		background-position: center;
		background-size: cover;
		height: 90px;
	}
	.insights-slug {
		margin-top: 25px;
		margin-bottom: 15px;

		.slug {
			font-size: 18px;

			a, i {
				color: #ccc;
			}

			i {
				margin: 0 5px;
			}

			.purple {
				color: $brand-pink;
			}
		}
	}
	.article-wrapper {
		.main-article {
			.article-image {
				img {
					width: 100%;
					height: auto;
				}
			}
			.article-title {
				h3 {
					a {
						font-size: 25px;
						line-height: 24px;
						color: $brand-pink;
						cursor: pointer;

						&:hover {
							text-decoration: none;
						}
						@media screen and (max-width: 600px) {
							font-size: 20px;
						}
					}
				}
			}
			.article-date {
				p {
					font-size: 15px;
					line-height: 15px;
					color: #ccc;
				}
			}
			.article-content {
				border-top: 2px solid #cccccc;
				padding-top: 15px;
				margin-bottom: 25px;
				font-family: 'Gibson-Regular','Gibson-SemiBold', sans-serif;

				p {
					font-size: 15px;
					line-height: 18px;
					color: #666666;
				}

				.ql-align-center {
					text-align: center;
				}

				img {
					&.half {
						width: 50%;
						margin: 0px 20px 0px 0px;
					}
				}
			}
		}
		.article-navigation {
			margin-bottom: 25px;

			@media screen and (max-width: 288px) {
				a {
					font-size: 18px;
				}
			}
		}
		.sidebar {
			.sidebar-article {
				margin-bottom: 25px;
				.article-image {
					width: 100%;
					height: 180px;
					background-size: cover;
					background-position: center;
				}
				.article-title {
					h3 {
						line-height: 18px;
					}

					a {
						font-size: 18px;
						color: $brand-pink;

						&:hover {
							text-decoration: none;
						}
					}
				}

				.article-excerpt {
					font-family: 'Gibson-Regular','Gibson-SemiBold', sans-serif;
					font-size: 15px;
					color: #666666;
					line-height: 18px;

					p {
						margin-bottom: 25px;
					}
				}
			}

		}
	}
}
