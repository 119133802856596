//$bootstrap-sass-asset-helper: false !default;
//
// Variables
// --------------------------------------------------


//== Colors
//
//## Gray and brand colors for use across Bootstrap.

//$gray-base:              #000 !default;
//$gray-darker:            lighten(//$gray-base, 13.5%) !default; // #222
//$gray-dark:              lighten(//$gray-base, 20%) !default;   // #333
//$gray:                   lighten(//$gray-base, 33.5%) !default; // #555
//$gray-light:             lighten(//$gray-base, 46.7%) !default; // #777
//$gray-lighter:           lighten(//$gray-base, 93.5%) !default; // #eee

//$brand-primary:         darken(#428bca, 6.5%) !default; // #337ab7
//$brand-success:         #5cb85c !default;
//$brand-info:            #5bc0de !default;
//$brand-warning:         #f0ad4e !default;
//$brand-danger:          #d9534f !default;

$brand-pink:          #a3195b !default;
$light-grey:        #a1a1a1;
$dark-grey:        #666;

//== Typography
//
$font-family-regular: 'Gibson-Regular', sans-serif;
$font-family-semi-bold: 'Gibson-SemiBold', sans-serif;


//== Media queries breakpoints

// Medium screen / desktop
$screen-md:                  992px !default;



//== Grid system
//
//## Define your custom responsive grid.
$grid-gutter-width: 20px;
$grid-float-breakpoint: $screen-md;



//== Navbar
// Basics of a navbar
$navbar-height: 130px;

//== Pagination
$pagination-color:                     $brand-pink !default;
$pagination-bg:                        #d1d1d1 !default;
$pagination-border:                    #a1a1a1 !default;

$pagination-hover-color:               $brand-pink !default;
$pagination-hover-bg:                  #a1a1a1 !default;
$pagination-hover-border:              #a1a1a1 !default;

$pagination-active-color:              #d1d1d1 !default;
$pagination-active-bg:                 $brand-pink !default;
$pagination-active-border:             #a1a1a1 !default;

//$pagination-disabled-color:            //$gray-light !default;
//$pagination-disabled-bg:               #fff !default;
$pagination-disabled-border:           #a1a1a1 !default;


