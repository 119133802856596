.purple-bg {
    background-color: $brand-pink !important;

    p {
        color: white;
    }

    select {
        margin-top: 15px;
    }
}

#partner_review_form {
	button {
		font-size: 16px;
		padding: 10px 16px;
	}
}

.pending-requests {
	.disputes {
		font-family: 'Gibson-Regular', sans-serif;
	}
}