#actions {
	margin: 2em 0;
}

.form-group-multi-input {
	// text-align: center;
}

.fileinput-button {
	position: relative;
	overflow: hidden;
	display: inline-block;
	margin-bottom: 10px;

	input {
		position: absolute;
		top: 0;
		right: 0;
		margin: 0;
	 	opacity: 0;
	 	-ms-filter: 'alpha(opacity=0)';
	 	font-size: 200px !important;
		direction: ltr;
		cursor: pointer;
	}
}

/*** custom checkboxes ***/
.profile {
	color: #666666;

	.grey-background {
		background-color: #e1e1e1;
	}

	.profile-image {
	    border-radius: 20px;
	    margin-bottom: 10px;
	    max-width: 100%;
	}

	.validation {
		display: inline-block;
		padding: 10px 0;

		.validation-message{

		}

		&.validation-success:before{
			font-family: FontAwesome;
			display: inline-block;
		    margin-right: 10px;
		    margin-left: -10px;
		    border-radius: 100px;
		    color: #fff;
		    background-color: #8dc63f;
		    height: 30px;
		    width: 30px;
		    line-height: 30px;
		    vertical-align: middle;
		    content: "\f00c";
		    text-align: center;
		    float: left;
		}

		.validation-danger:before{
			font-family: FontAwesome;
			display: inline-block;
		    margin-right: 10px;
		    border-radius: 100px;
		    color: #fff;
		    background-color: $alert-danger-text;
		    height: 30px;
		    width: 30px;
		    line-height: 30px;
		    vertical-align: middle;
		    content: "\f12a";
		    text-align: center;
		    float: left;
		}
	}

	.btn.btn-default{
		font-size: 18px;

		&#btnAddRef {
			background: #8dc63f;
			font-size: 20px;
			padding-bottom: 3px;
		}
	}

	.btn-add-reference {
		span{
			font-family: 'Gibson-Regular', sans-serif;
			font-size: 18px;
			margin-left: 20px;
		}
	}

	a.terms_link{
		font-size: 16px;
	}

	.terms_info_container{
		margin-left: 40px;
	}

	input[type=checkbox] + label {
		padding-left: 40px;
	}

	input[type=checkbox] { display:none; } /* to hide the checkbox itself */
	input[type=checkbox] + label:before {
		font-family: FontAwesome;
		display: inline-block;
	    margin-right: 8px;
	   	margin-left: -40px;
	    border-radius: 100px;
	    color: #fff;
	    background-color: #fff;
	    height: 30px;
	    width: 30px;
	    line-height: 30px;
	    vertical-align: middle;
	    content: "\f00c";
	    text-align: center;
	}

	input[type=checkbox]:checked + label:before {
		content: "\f00c";
	    background-color: #8dc63f;
	}

	input[type=radio] { display:none; } /* to hide the checkbox itself */

	input[type=radio] + label {
		padding-left: 40px;
	}

	input[type=radio] + label:before {
		font-family: FontAwesome;
		display: inline-block;
	    margin-right: 8px;
	   	margin-left: -40px;
	    border-radius: 100px;
	    color: #fff;
	    background-color: #fff;
	    height: 40px;
	    width: 40px;
	    line-height: 30px;
	    vertical-align: middle;
	    content: "\f00c";
	    text-align: center;
	    font-size: 20px;
    	padding-top: 5px;
	}

	input[type=radio]:checked + label:before {
		content: "\f00c";
	    background-color: #8dc63f;
	}
}

.form-group-box {
	background-color: #d1d1d1;
    border-radius: 30px;
    padding: 10px;

    .progress {
    	height: 40px;
    	border-radius: 20px;
    }

    &.gender {
    	padding: 5px 10px;

    	label {
    		padding: 5px 10px 5px 20px;
    	}
    }

    label {
    	padding: 10px 10px 10px 20px;
    	color: #666666;
    	margin-bottom: 0;
    	font-size: 16px;
    }

    label.terms{
    	padding: 10px 10px 0px 20px;
    }

    .no-padding-right{
    	@media screen and (min-width: $screen-md) {
			padding-right: 0;
		}

    }

    .form-group-multi-input{
    	input.text:not(:last-child), input.text:only-child{
    		margin-bottom: 10px;
    	}

    }
    .map{
    	width: 100%;
    	height: 350px;
    	border-radius: 30px;
    }

    textarea[name='awards'] {
    	padding: 20px;
    }

    @media screen and (max-width: $screen-md) {
		a.btn-delete {
			margin-left: 50%;
			margin-top: 10px;
			transform: translateX(-50%);
			margin-bottom: 10px;
		}

		label {
			padding: 10px;
		}
	}
}

.line-borders{
	padding-top: 10px;
	padding-bottom: 10px;
	margin-top: 20px;
	margin-bottom: 20px;

	&.line-borders-top{
		border-top: 1px solid #ffffff;
	}
	&.line-borders-bottom{
		border-bottom: 1px solid #ffffff;
	}
	&.line-borders-both{
		border-top: 1px solid #ffffff;
		border-bottom: 1px solid #ffffff;
	}

	a[name="back"], button[name="register"] {
		margin-top: 25px;
	}

	@media screen and (max-width: $screen-md) {
		button[name="register"] {
			margin-top: 10px;
		}
	}
}

.profile {

	font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;

	.navbar {
		top: -18px;

		.navbar-brand {
			img {
				width: 280px;
				height: auto;

				@media screen and (max-width: 440px) {
					width: 200px;
				}
				@media screen and (max-width: 275px) {
					width: 150px;
				}
			}
		}

	}
	header.sticky  {
		.navbar-brand img {
		    padding-top: 28px;
		}
		.navbar-nav {
			margin-top: 10px;
		}
	}

	@media screen and (min-width: 992px) {
		header .navbar-nav > li {
		    margin-top: 35px;
		}
	}
	.hero {
		background-image: url('../../assets/img/login-register/nav-bg.png');
		background-position: top center;
		background-size: cover;
		height: 190px;

		.middle {
			h2 {
				margin-top: 80px;
			}
		}
		@media screen and (max-height: 690px) {
			.middle {
				h2 {
					font-size: 26px;
				}
			}
		}
		@media screen and (max-width: 1500px) {
			.middle {
				h2 {
					font-size: 40px;
				}
			}
		}
		@media screen and (max-width: 944px) {
			.middle {
				h2 {
					font-size: 26px;
				}
			}
		}
	}

	.login-register {
		background-image: url('../../assets/img/login-register/hero-bg.png');
		background-size: cover;
		background-repeat: none;
		background-position: bottom center;
		padding-bottom: 175px;
		position: relative;

		input {
			font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
		}

		.register {
			h2 {
				color: $brand-pink;
				font-size: 36px;
				margin-top: 43px;
				margin-bottom: 25px;
			}

			p {
				color: #666;
				font-size: 17px;
				font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
				margin-bottom: 33px;
				max-height: 50px;
			}
		}

		.login {
			h2 {
				color: #ffffff;
				font-size: 36px;
			}

			p {
				color: #ffffff;
				font-size: 17px;
				font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
			}
		}

		.gradient {
			background: -moz-linear-gradient(left,  rgba(0,0,0,0.2) 0%, rgba(255,255,255,0) 100%);
			background: -webkit-linear-gradient(left,  rgba(0,0,0,0.2) 0%,rgba(255,255,255,0) 100%);
			background: linear-gradient(to right,  rgba(0,0,0,0.2) 0%,rgba(255,255,255,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33000000', endColorstr='#00ffffff',GradientType=1 );
			position: absolute;
			height: 410px;
			width: 936px;
			top: 225px;

			@media screen and (max-width: $screen-md) {
				top: 677px;
				width: 100%;
			}

			@media screen and (max-width: 667px) {
				width: 100%;
			}

			@media screen and (max-width: 513px) {
				display: none;
			}
		}

		.register-inputs {
			padding-top: 33px;
			border-bottom-left-radius: 30px;

			.who {
				font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
				border-radius: 30px;
				font-size: 18px;
				padding-left: 20px;
				padding-right: 20px;
				width: 100%;
				background-color: white;
				border: 0;

				&:focus {
					border: none;
					box-shadow: none;
					outline: none;
				}

				@media screen and (max-width: $screen-sm) {
					width: 100%;
				}
			}

			.register-area {
				border-bottom: 1px solid white;
				margin-bottom: 20px;

				.confirm-pass {
					margin-bottom: 20px;
				}

				@media screen and (max-width: 767px) {
					margin-top: 40px;
				}

				@media screen and (max-width: 751px) {
					margin-top: 0px;
				}

				@media screen and (max-width: 667px) {
					margin-top: -23px;
				}
			}

			button {
				width: 63%;
				margin-left: 9px;
			}
		}

		.login-inputs {
			background: -moz-linear-gradient(top,  rgba(0,0,0,0.4) 0%, rgba(255,255,255,0) 100%);
			background: -webkit-linear-gradient(top,  rgba(0,0,0,0.4) 0%,rgba(255,255,255,0) 100%);
			background: linear-gradient(to bottom,  rgba(0,0,0,0.4) 0%,rgba(255,255,255,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33000000', endColorstr='#00ffffff',GradientType=0 );
			margin-top: -1px;
			margin-left: 100px;
			padding-left: 60px;
			padding-right: 60px;

			.login-area {
				padding-top: 33px;
			}

			h2 {
				margin-top: 0px;
				margin-bottom: 25px;
				padding-top: 44px;
				font-size: 36px;
				line-height: 36px;
			}

			p {
				font-size: 16px;
				line-height: 24px;
				font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
				margin-bottom: 33px;
			}

			.checkbox {
				.checkbox-label {
					font-size: 14px;
					color: #ccc;
					font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
				}
			}

			.forgot-password {
				a {
					color: white;
					float: right;
				}
			}

			@media screen and (max-width: $screen-lg) {
				padding-left: 30px;
				padding-right: 30px;
			}

			@media screen and (max-width: $screen-md) {
				margin-left: 0;
			}
		}
	}
}

.partner-dashboard {
	font-family: 'Gibson-SemiBold','Gibson-Regular', sans-serif;

	.navbar-fixed-top.sticky .navbar-nav {
		margin-top: 25px;
	}

	.page-wrapper {
		background-color: #e1e1e1;
		width: 100%;
		height: auto;
	}

	.main-content {
		.title-area {
			h2 {
				color: $brand-pink;
				font-size: 24px;
			}

			h3, h4 {
				color: $brand-pink;
			}

			a {
				font-size: 18px;
				float: right;
				color: $dark-grey;

				&.pink{
					color: $brand-pink;
				}
			}
			&.jobs {
				h2, h3, h4 {
					float: left;
					margin-top: 0;
					margin-bottom: 0;
				}
				p {
					float: left;
					margin-top: 0;
					margin-bottom: 10px;
				}
			}
		}
		.calendar-area {
			.placeholder {
				img {
					width: 100%;
					height: 305px;
				}
			}
			.keys {
				background-color: white;
				width: 100%;
				height: auto;
				margin-top: 10px;
				padding: 7px 15px 0px 15px;

				p {
					font-size: 15px;
					font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
					color: #a1a1a1;
					float: left;
					&.first {
						font-family: 'Gibson-SemiBold','Gibson-Regular', sans-serif;

					}
				}
				.key {
					float: left;

					div {
						float: left;
						height: 25px;
						width: 25px;
						border-radius: 50%;
						border: 1px solid #a5a5a5;
						margin-right: 15px;
						margin-left: 50px;
						&.grey {
							background-color: #e1e1e1;
						}
						&.orange {
							background-color: #ffa800;
						}
						&.red {
							background-color: #9e0b0f;
						}
						&.green {
							@include gradient(#8cc63f, #65ad05);
						}
						&.pink {
							@include gradient();
						}
					}
				}
			}

			@media screen and (max-width: $screen-md) {

				.keys {
					p {
						float: none;
					}

					.key {
						float: none;
					}
				}
			}
		}
		.job-requests-section, .jobs-section {
			.requests {
				.title-of-section {
					color: #666;
					font-size: 18px;
				}
				.request {
					background-color: #d1d1d1;
					border-radius: 60px;
					width: 100%;
					height: 60px;
					margin-bottom: 10px;
					padding: 10px;
					.date-selected {

						min-width: 180px;
						// recenters vertically
						margin-top: -2px;
						p {
							color: #666;
							font-size: 16px;
							margin-bottom: 0;
							margin-left: 50px;
						}
					}
					.icon-bubble {
						margin-right: 10px;
					}
					.location {
						min-width: 130px;
					}
					.location-text {
						margin-top: 3px;
						p {
							font-size: 16px;
							color: #666;
							line-height: 18px;
							span {
								font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
							}
						}
					}
					.length {
						margin-left: 50px;
						.length-text {
							margin-top: 10px;
							p {
								font-size: 16px;
								color: #666;
							}
						}
						@media screen and (max-width: $screen-lg) {
							margin-left: 15px;
						}
					}
					.time {

						.time-text {
							margin-top: 10px;
							p {
								font-size: 16px;
								color: #666;
							}
						}
						@media screen and (max-width: $screen-lg) {
							margin-left: 15px;
						}
					}
					.details {
						select {
							background: url('../../assets/img/dropdown-arrow.png') no-repeat 85% #fff;
							background-size: 12px;
							background-color: white;
							font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
							height: 40px;
							width: 120px;
							border: none;
							border-radius: 20px;
							color: #666;
							font-size: 15px;
							padding-left: 15px;
						}

						//HIDE DEFAULT ARROW
						select::-ms-expand {	display: none; }
						select{
						    -webkit-appearance: none;
						    appearance: none;
						}
					}
					.decision {
						button {
							width: 120px;
							height: 40px;
							text-align: left;
							color: white;
							font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;
							transition: all .5s;
							img {
								float: right;
								height: 15px;
								width: 15px;
								margin-top: 3px;
							}
							&.btn-accept {
								@include gradient(#8dc63e, #6aae0d);
								img {
									width: 20px;
								}
								&:hover {
									@include gradientReverse(#6aae0d, #8dc63e);
								}
							}
							&.btn-decline {
								@include gradient(#f01616, #d12525);
								margin: 0px 10px 0px 10px;

								&:hover {
									@include gradientReverse(#d12525, #f01616);
								}
							}
							&.btn-details {
								background-color: #fff;
								color: #666;
								margin: 0px 10px 0px 10px;
								background: url('../../assets/img/dropdown-arrow.png') no-repeat 50% #fff;
								background-size: 20px 10px;
								background-position: right 10px center;
								&:hover {
									background-color: #d1cfd0;
								}
							}
						}
						.btn.btn-default{
							font-size: 14px;
							line-height: 28px;
							margin-left: 10px;
						}
						//pending job requests
						&.final {
							p {
								font-size: 16px;
								color: #666;
								margin: 7px 10px 0px 20px
							}
						}
					}
					@media screen and (max-width: $screen-md) {
						height: auto;

						.where-when {
							width: 295px;
    						margin: 0 auto;
						}
						.details-wrap {
							width: 400px;
    						margin: 10px auto 0px auto;
						}
						.details-wrap, .where-when, .decision.final, .details.final  {
							float: none !important;
						}
						.details.final {
							width: 120px;
							margin: 0 auto;
						}
						.decision.final {
							text-align: center;
						}
					}
					@media screen and (max-width: 500px) {
						.details-wrap {
							width: 120px;
							margin: 0 auto;
						}
						.btn-accept, .btn-decline, .btn-details {
							margin: 10px 0px 0px 0px !important;
						}
						.location, .length, .decision, .details {
							float: none !important;
						}
						.length, .location {
							margin-left: 50%;
							margin-bottom: 10px;
    						transform: translateX(-50%);
						}
					}
				}
			}
		}
	}
}


.partner-dashboard, .partner-profile {
	.sidebar {
		background-color: #ebebeb;
		padding: 20px 10px 10px 10px;
		margin-bottom: 20px;

		#menu-button {
			position: relative;
			padding-left: 15px;
			background: #a1a1a1;
			font-size: 18px;
			color: white;
			text-align: left;
			width: 100%;
			margin-bottom: 10px;

			&:hover, &.active {
				@include gradient();
			}

		}

		ul {
			padding-left: 0;

			li {
				list-style: none;
				margin-bottom: 10px;

				a {
					position: relative;
					padding-left: 15px;
					background: #a1a1a1;
					font-size: 18px;
					color: white;
					text-align: left;

					&:hover, &.active {
						@include gradient();
					}

					&.notif {
						padding: 5px 5px 5px 15px;
					}

					.icon-area {
						position: absolute;
						right: 13px;
					}

					i.fa {
						margin-left: 10px;
					}

					.amount-notif {
						position: absolute;
						right: 5px;
						color: $brand-pink;
						background-color: white;
						font-size: 16px;
						border-radius: 50%;
						padding: 2px 10px;
					}
				}
			}
		}
	}

	// Requests on chef profile when a resturant views it
	.request-selected-days {
		background-color: #d1d1d1;
		width: 100%;
		height: 60px;
		border-radius: 60px;
		padding: 10px 10px;
		margin-bottom: 10px;

		.total-values, .range-selectors, .book-from, .date-selected, .cancel {
			float: left;
		}

		.cancel {
			margin: 12px 12px 0px 12px;

			img {
				width: 20px;
				height: 15px;
			}
		}

		.date-selected {
			min-width: 100px;
			// recenters vertically
			margin-top: -2px;
			p {
				color: #666;
				font-size: 16px;
				margin-bottom: 0;
			}
		}
		.book-from {
			.icon-bubble, .text {
				float: left;
			}

			.icon-bubble {
				margin-right: 15px;
			}

			.text {
				// recenters vertically
				margin-top: -2px;
				margin-right: 10px;

				p {
					font-size: 16px;
					color: #666;
					margin-bottom: 0;
				}
			}
		}

		.range-selectors {
			select, .splitter {
				float: left;
			}
			select {
				background: url('../../assets/img/dropdown-arrow.png') no-repeat 85% #fff;
				background-size: 12px;
				background-color: white;
				font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
				width: 110px;
				height: 40px;
				border-radius: 40px;
				border: 0;
				appearance: none;
				font-size: 15px;
				color: #666;
				padding-left: 15px;
			}
			.splitter {
				margin: 0px 15px;
				p {
					font-size: 15px;
					color: #666666;
					font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
					margin-top: 8px;
				}
			}
		}
		.total-values {
			.icon-bubble, .hourly-rate-text, .total-price-text {
				float: left;
			}
			.icon-bubble {
				margin: 0px 15px 0px 15px;
			}
			.hourly-rate-text, .total-price-text {
				// recenters vertically
				margin-top: -2px;
				p {
					font-size: 16px;
					color: #666;
					margin-bottom: 0;
				}
			}
			.total-price-text {
				margin-left: 15px;
			}
		}
		@media screen and (max-width: $screen-lg) {
			.date-selected {
				min-width: 100px;
			}

			.range-selectors {
				.splitter {
					margin: 0px 5px;
				}
			}

			.book-from {
				.icon-bubble {
					display: none;
				}
			}

			.total-values {
				.icon-bubble {
					display: none;
				}
				.total-price-text, .hourly-rate-text {
					margin-left: 10px;
				}
			}
		}
		@media screen and (max-width: $screen-sm) {
			height: auto;

			.total-values, .range-selectors, .book-from {
				float: none;
			}

			.request-wrapper {
				width: 300px;
    			margin: 0 auto;
			}

			.book-from {
				margin-bottom: 10px;
			}

			.range-selectors {
				margin-bottom: 10px;

				select {
					display: block;
					margin: 10px auto;
				}
			}

			.total-values {
				width: 200px;
				margin: 0 auto;
			}
		}
		@media screen and (max-width: 400px) {
			.range-selectors {
				text-align: center;
				select, .splitter {
					float: none;
				}
			}
			.book-from {
				.icon-bubble {
					display: none;
				}
				.text {
					width: 100%;
					p {
						text-align: center;
					}
				}
			}
			.date-selected {
				min-width: 80%;
				margin-bottom: 10px;
				p {
					text-align: center;
				}
			}
			.total-values {
				width: 50%;
			}
		}
	}
	.update {
		width: 100%;
		height: 60px;
		border-radius: 60px;
		background-color: #d1d1d1;
		padding: 10px 10px 10px 50px;
		margin: 10px 0px;

		button {
			float: right;
			width: 240px;
			height: 40px;
			color: white;
			font-size: 18px;
			@include gradient();

			i {
				float: right;
				margin-top: 3px;
			}

			&:hover {
				@include gradientReverse();
			}
		}

		@media screen and (max-width: $screen-md) {
			padding: 10px;

			button {
				float: none;
				display: block;
				width: 100%;
			}
		}
	}

	.total {
		width: 100%;
		height: 60px;
		border-radius: 60px;
		background-color: white;
		padding: 10px 10px 10px 50px;
		margin: 10px 0px;

		.total-hours-wrapper, .total-cost-wrapper {
			float: left;

			p {
				color: $brand-pink;
				font-size: 17px;
				margin-bottom: 0;

				&:first-child {
					color: #666;
					margin-top: -3px;
				}
			}
		}

		.total-hours-wrapper {
			margin-right: 40px;
			min-width: 110px;

			@media screen and (max-width: $screen-lg) {
				margin-right: 10px;
			}
		}

		button {
			float: right;
			width: 240px;
			height: 40px;
			color: white;
			font-size: 18px;
			@include gradient(#8dc63f, #65ac03);

			i {
				float: right;
				margin-top: 3px;
			}

			&:hover {
				@include gradient(#65ac03, #8dc63f);
			}
		}
		@media screen and (max-width: $screen-sm) {
			height: auto;
			padding: 10px;

			button, .total-hours-wrapper, .total-cost-wrapper {
				float: none;
			}

			button {
				margin: 0 auto;
			}

			.total-cost-wrapper, .total-hours-wrapper {
				margin-bottom: 10px;

				p {
					text-align: center;
				}
			}

			.total-hours-wrapper {
				margin-right: 0;
			}
		}
		@media screen and (max-width: 350px) {
			button {
				width: 80%;
			}
		}
	}
}
