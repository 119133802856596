@font-face {
    font-family: 'blackjack';
    src: url(../../fonts/blackjack.otf);
    src: url(../../fonts/blackjack.ttf);
    src: url(../../fonts/blackjack.woff);
}
@font-face {
    font-family: 'Gibson-Bold';
    src: url(../../fonts/Gibson-Bold.eot);
    src: url(../../fonts/Gibson-Bold.ttf);
    src: url(../../fonts/Gibson-Bold.woff);
    src: url(../../fonts/Gibson-Bold.woff2);
    src: url(../../fonts/Gibson-Bold.otf);
}
@font-face {
    font-family: 'Gibson-SemiBold';
    src: url(../../fonts/Gibson-SemiBold.eot);
    src: url(../../fonts/Gibson-SemiBold.ttf);
    src: url(../../fonts/Gibson-SemiBold.woff);
    src: url(../../fonts/Gibson-SemiBold.woff2);
    src: url(../../fonts/Gibson-SemiBold.otf);
}
@font-face {
    font-family: 'Gibson-Regular';
    src: url(../../fonts/Gibson-Regular.eot);
    src: url(../../fonts/Gibson-Regular.ttf);
    src: url(../../fonts/Gibson-Regular.woff);
    src: url(../../fonts/Gibson-Regular.otf);
}
.color-pink {
    color: $brand-pink;
}

p {
    font-size: 14px;
    color: #666666;
}

.small , .small-nav {
    font-size: initial;
}

p.regular-16pt{

    font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
    font-size: 16px;
}
