footer {
	background-color: #252525;
	text-align: center;

	.logo {
		img {
			height: 80px;
			margin-top: 40px;
			margin-bottom: 25px;

			@media screen and (max-width: 300px) {
				height: 40px;
			}
		}
	}

	.nav-links {
		position: relative;
		overflow-x: hidden;
		background-image: url('../../assets/img/footer/test.jpg');
		background-repeat: repeat-x;
		background-size: contain;
		margin-bottom: 20px;

		@media screen and (max-width: 620px) {
			background: none;
		}

		&.locations {
			margin-bottom: 30px;
		}

		ul {
			padding-left: 0;
			text-align: center;
			background-color: #252525;
			display: inline;


			li {
				list-style: none;
				display: inline;
				transition: color 0.25s;
				color: white;
				margin-left: 20px;
				font-size: 18px;
				font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;

				:hover {
					text-decoration: none;
					color: #a2195c;
				}

				@media screen and (max-width: $screen-md) {
					margin-left: 0;
				}

				@media screen and (min-width: 620px) {
					&.left {
						padding-left: 5px;
					}

					&.last {
						padding-right: 25px;
					}
				}

				@media screen and (max-width: 390px) {
					display: block;
				}

				a {
					color: white;
					font-size: 18px;
					font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;

					@media screen and (max-width: 620px) {
						margin-left: 10px;
					}

					@media screen and (max-width: 390px) {
						margin-left: 0px;
					}
				}
			}
		}
	}
	.contact-info {
		color: white;
		font-size: 24px;
		font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
		line-height: 40px;

		@media screen and (max-width: 300px) {
			font-size: 18px;
		}

		a {
			color: white;
			font-size: 24px;
			font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;

			@media screen and (max-width: 300px) {
				font-size: 18px;
			}

			&:hover {
				text-decoration: none;
				color: #a2195c;
			}

			&:first-of-type {
				@media screen and (min-width: 992px) {
					padding-right: 60px;
				}
			}
		}
	}

	.footer-bottom {
		margin-top: 30px;
    	padding-bottom: 80px;
    	color: white;
    	font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;

		p {
			font-size: 15px;
			margin-top: 7px;
			color: white;
		}
		.copyright {
			float: left;
		}

		.reckless {
			float: right;
		}

		.social {
			color: white;
			@include gradient();
			font-size: 30px;
			border-radius: 50%;
			transition: all 0.5s;

			i {
				width: 27px;
				height: 30px;
			}

			&.fb {
				margin-right: 20px;
			}

			&.in {
				margin-left: 20px;
			}

			&.fb, &.tw, &.in {
				padding: 12px 17px;

				&:hover {
					color: purple;
				}
			}

			&:hover {
				background: white;
			}
		}

		img {
			width: 105px;
		}

		.col-sm-12 {
			margin-top: 10px;
			margin-bottom: 10px;
		}

		@media screen and (max-width: $screen-md) {
			.copyright, .reckless {
				float: none;
			}
		}
	}
}