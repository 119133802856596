.insights {
	font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;

	/* Navbar redone as different to al other pages */

	header {
		@media screen and (min-width: $screen-md) {
			height: 90px
		}
	}

	.navbar {
		top: -16px;

		.navbar-nav {
			margin-top: 4px;
		}

		.navbar-toggle {
			@media screen and (max-width: $screen-md) {
				margin-bottom: 23px;
			}
		}

		.navbar-brand {
			height: 90px;

			img {
				width: 260px;
				height: auto;

				@media screen and (max-width: 1400px) {
					padding-top: 10px;
				}

				@media screen and (max-width: $screen-sm) {
					width: 200px;
					margin-top: 7px;
					padding-top: 30px;
				}

				@media screen and (max-width: 275px) {
					width: 150px;
				}
			}

			@media screen and (max-width: $screen-md) {
				height: 100px
			}
		}

	}

	header.sticky  {
		.navbar-brand img {
		    padding-top: 28px;
		}

		.navbar-nav {
			margin-top: 10px;
		}
	}

	@media screen and (max-width: 1300px) {
		header.sticky .navbar-brand img {
			width: 150px !important;
			padding-top: 40px;
		}

		header .navbar-nav > li > a {
		    padding: 10px;
		}
	}

	@media screen and (max-width: 1150px) {
		header .navbar-nav > li > a {
		    padding: 10px 5px;
		}
	}

	@media screen and (min-width: 992px) {
		header .navbar-nav > li {
		    margin-top: 30px;
		}
	}

	@media screen and (max-width: 768px) {
		header.sticky {
		    height: 100px;
		}

		header.sticky .navbar-brand img {
			width: 200px !important;
			padding-top: 28px;
		}

		header.sticky .navbar-toggle {
			margin-top: 50px;
			margin-right: 30px;
		}
	}

	.hero {
		background-image: url('../../assets/img/insights/nav-bg.png');
		background-position: center;
		background-size: cover;
		height: 90px;
	}

	.insights-slug {
		margin-top: 25px;
		margin-bottom: 15px;

		.slug {
			font-size: 18px;

			a, i {
				color: #ccc;
			}

			i {
				margin: 0 5px;
			}

			.purple {
				color: $brand-pink;
			}
		}
	}

	.related-wrapper {
		select {
			width: 500px;
		}
	}

	.main-article {
		margin-bottom: 20px;

		.large-article {
			position: relative;
			height: 375px;

			.large-article-image {
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				width: 100%;
				height: 375px;
			}

			.large-article-info {
				padding: 0px 20px 20px 20px;
				position: absolute;
				top: 0;
				right: 0;
				background-color: rgba(black, 0.4);
				height: 100%;
				width: 380px;
				color: white;

				.large-article-title {
					a {
						font-size: 22px;
						color: white;

						&:hover {
							text-decoration: none;
						}
					}
				}

				.large-article-description {
					font-size: 15px;
					font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
					line-height: 18px;
					color: white;
				}

				@media screen and (max-width: $screen-lg) {
					position: static;
					background-color: rgba(black, 0.5);
					width: 100%;
					padding-top: 20px;
					height: 100%;

					.large-article-title {
						margin-top: 0;
					}
				}

				@media screen and (max-width: $screen-sm) {
					.large-article-title {
						font-size: 18px;
					}

					.large-article-description {
						font-size: 12px;
					}
				}
			}
		}
	}

	.articles {
		padding-bottom: 40px;
	}

	.article-small {
		margin-bottom: 20px;

		.article-image {
			background-size: cover;
			background-position: center;
			width: 100%;
			height: 180px;
		}

		.article-info {
			padding-right: 20px;

			.article-title {
				font-size: 18px;
				line-height: 20px;

				a {
					color: $brand-pink;

					&:hover {
						color: darken($brand-pink, 5%);
						text-decoration: none;
					}
				}
			}

			.article-description {
				font-size: 15px;
				color: #666666;
				font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
				line-height: 18px;
			}
		}
	}
}
