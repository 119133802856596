.has-error .form-control {
	border: 1px solid #a94442;
}
.help-block {
	position: absolute;
    right: -230px;
    top: 6px;
    @media screen and (max-width: $screen-md) {
    	position: static;
    }
}

textarea {
	resize: vertical;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 16px;
}
::-moz-placeholder { /* Firefox 19+ */
  font-size: 16px;
}
:-ms-input-placeholder { /* IE 10+ */
  font-size: 16px;
}
:-moz-placeholder { /* Firefox 18- */
  font-size: 16px;
}

#calendar {
	.ui-datepicker {
        font-size: 12pt !important;
        font-family: 'Gibson-SemiBold','Gibson-Regular', sans-serif !important;
    }
}

.hero {
	text-align: center;
	font-family: 'Gibson-SemiBold', sans-serif;
	position: relative;
	background-size: cover;
	background-position: center;

	form {
		input, button {
			height: 40px;
		}

		button {
			padding: 0px 20px;
			line-height: 1;
			font-family: 'Gibson-SemiBold', sans-serif;
			font-size: 18px !important;
		}
	}
}
.hero.small {
	background-image: url('../../assets/img/search/nav-bg.png');
	background-position: top center;
	background-size: cover;
	height: 190px;

	.middle {
		h2 {
			margin-top: 80px;
		}
	}

	@media screen and (max-width: 1500px) {
		.middle {
			h2 {
				font-size: 40px;
			}
		}
	}

	@media screen and (max-width: 944px) {
		.middle {
			h2 {
				font-size: 26px;
			}
		}
	}
}
.middle {
	position: absolute;
	top: 50%;
	left: 50%;
    transform: translate(-50%, -50%);
    color: white;

    h2 {
    	font-size: 50px;

    	@media screen and (max-width: 520px) {
			font-size: 36px;
		}
    }

    .fancy {
    	font-size: 36px;
    	color: white;

    	@media screen and (max-width: 542px) {
			font-size: 25px;
		}
    }

}
.bottom {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	color: white;

	p {
		font-size: 18px;
	}

	i {
		font-size: 70px;
	}

	@media screen and (max-height: 630px) {
		p {
			font-size: 14px;
		}

		i {
			font-size: 40px;
		}
	}
}

.403 {
    height: 600px !important;
    background-color: #ccc !important;
}

.icon-bubble {
	float: left;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	background-color: white;
	text-align: center;
	padding-top: 7px;

	img {
		width: 50%;
	}
}

select {
	&:focus {
		outline: none !important;
	}
}

.small-nav {
	.navbar-fixed-top.sticky .navbar-nav {
		margin-top: 24px;
	}
}

p {
	&.small-weight {
		font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
	}
}

.flash_messages{
	// Have to set the font. Different pages don't share styles.
	font-family: 'Gibson-SemiBold', 'Gibson-Bold', sans-serif;
	> div:first-child{
		margin-top: 20px;
	}

	.alert-gocardless {
	    background-color: rgb(217, 237, 247);
	    color: rgb(49, 112, 143);
	    border-color: rgb(188, 232, 241);
	    font-weight: 900;

	}
}
