.customer-profile {
	.page-wrapper {
		font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;
		background-color: #e1e1e1;
		font-size: 14px;

		.title-area {
			h2 {
				color: $brand-pink;
				font-size: 24px;
				text-transform: lowercase;
			}
		}

		h4.name {
			font-size: 30px;
			color: $brand-pink;
			margin-bottom: 0;
			text-transform: lowercase;
		}

		p.location {
			font-size: 24px;
			color: #a1a1a1;
			text-transform: lowercase;
			margin-bottom: 0;
		}

		.customer-image-wrapper {
			background-color: #d1d1d1;
			padding: 10px 10px 1px 10px;
			border-radius: 30px;

			.chef-image {
				width: 100%;
				height: 260px;
			}

			@media screen and (max-width: $screen-md) {
				margin-bottom: 10px;
			}
		}

		.map-area {
			background-color: #d1d1d1;
			padding: 10px;
			border-radius: 30px;

			.map {
				background-size: cover;
				background-position: center;
				border-radius: 30px;
				width: 100%;
				height: 260px;
				pointer-events: none;

				div {
					border-radius: 30px;
				}
			}
		}

		.specialities{
			color: #666666;
			font-size: 14px;
		}

		.details {
			margin: 15px 0px 20px 0px;

			p {
				color: $brand-pink;
				font-size: 20px;
			}

			p:first-child {
				margin-bottom: 0;
				color: #a1a1a1;
			}

		}

		.links, .description {
    		font-size: 14px;
    		color: #666666;

			a {
				color: $brand-pink;
			}

			ul.list {
				padding-left: 0;

				li {
					list-style-type: none;
					color: #9f1b67;

					a {
						color: #9f1b67;
					}
				}
			}
		}
	}
}
