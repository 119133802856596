.chefs {
	font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;

	.hero, .quick-easy {
		form {
			.email {
				width: 380px;
				margin-right: 20px;
				color: black;

				@media screen and (max-width: 1030px) {
					margin-bottom: 30px;
				}

				@media screen and (max-width: 450px) {
					width: 100%;
				}
			}

			a.btn-primary {
		    	font-family: 'Gibson-Regular', sans-serif;
				font-size: 24px;
				background: none;
				border: 2px solid white;
				border-radius: 30px;
				padding: 11px 35px;
				height: 40px;
				font-weight: 100;
				margin: 40px 10px;
				transition: background-color 0.5s;

				&:hover {
					background-color: rgba(0, 0, 0, 0.3);
				}

				@media screen and (max-width: $screen-md) {
					margin: 40px 5px;
				}

				@media screen and (max-width: 712px) {
					margin: 10px 0px;
					padding: 11px 60px;
				}
		    }
		}
	}

	.hero {
		background-image: url('../../assets/img/Chefs/hero-bg.jpg');
		background-position: bottom center;
		height: 90vh;

		button.btn-default {
			height: 40px;
			padding: 0px 20px;
			margin-top: -4px;
		}

		@media screen and (max-height: 690px) {
			height: 100vh;
		}

		.middle {
			h2 {
				@media screen and (max-height: 690px) {
					font-size: 26px;
				}
			}
		}

		.form {
			margin-top: 90px;

			@media screen and (max-height: 690px) {
				margin-top: 30px;
			}
		}

		.bottom {
			bottom: 0px !important;
		}

		.faded {
			background-color: rgba(0, 0, 0, 0.4);
			width: 100%;
			padding-top: 33px;
			padding-bottom: 33px;

			img {
				width: 80px;
				float: left;
				margin-right: 22px;
				opacity: 0;
			}

			p {
				font-size: 24px;
				line-height: 24px;
				margin-top: 15px;
				text-align: left;
				color: white;
			}

			@media screen and (max-width: $screen-md) {
				background: none;
			}
		}
	}

	.quick-easy {
		background-image: url('../../assets/img/Employers/quick-and-easy-bg.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top center;
		font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;
		text-align: center;
		color: white;

		.title-pink {
			color: $brand-pink;
			font-size: 40px;
			margin-top: 50px;
			margin-bottom: 60px;
		}

		.box {
			background-color: rgba(0, 0, 0, 0.3);
			border-radius: 10px;
			height: 500px;
			width: 100%;
			padding: 63px 30px;
			opacity: 0;
			transition: background-color 0.25s;

			&:hover {
				background-color: rgba(152,26,120, 0.8);
			}

			.box-title {
				font-size: 24px;
				margin-bottom: 40px;
			}

			.box-description {
				font-size: 15px;
				font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
				color: white;
			}

			.box-image {
				min-height: 146px;

				.chef-hat {
					width: calc(142px/2);
				}

				.right-chef {
					width: calc(180px/2);
					margin-top: 10px;
				}

				.card {
					width: calc(178px/2);
					margin-top: 10px;
				}

				.sit-back {
					width: calc(180px/2);
				}
			}

			//box
			@media screen and (max-width: $screen-lg) {
				margin-bottom: 20px;
			}

			@media screen and (max-width: $screen-sm) {
				height: auto;
			}
		}

		.singup-info {
			margin-bottom: 110px;

			h2 {
				margin: 55px 0px;
				font-size: 50px;
				line-height: 50px;
			}

			button {
				height: 40px;
			}
		}

		//quick-easy
		@media screen and (max-width: 575px) {
			button {
				margin-bottom: 30px;
			}
		}

		@media screen and (max-width: $screen-sm) {
			padding-left: 20px;
			padding-right: 20px;

		}
	}
	.salary-calculator {
		background-color: #a11961;
		width: 100%;
		color: white;
		text-align: center;

		h2 {
			font-size: 40px;
			margin-bottom: 40px;
		}

		p {
			font-size: 25px;
			color: white;
		}

		.rate {
			position: relative;

			&:after {
				content: '+';
				position: absolute;
				top: 15px;
				right: -20px;
				font-size: 35px;

				@media screen and (max-width: $screen-md) {
					display: none;
				}
			}
		}

		.rate, .hours {
			padding: 0px 30px;

			.input-wrapper {
				border: 2px dashed #c675a3;
				border-radius: 20px;
				height: 80px;
				padding-top: 8px;

				input {
					border: none;
					background: none;
					font-size: 40px;
					text-align: center;
					width: 100%;


					&:focus {
						outline: 0;
					}
				}

				input[type=number]::-webkit-inner-spin-button,
				input[type=number]::-webkit-outer-spin-button {
				  -webkit-appearance: none;
				  margin: 0;
				}

				::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				  color: rgba(#eeeeee, 0.3);
				  font-size: 40px;
				}

				::-moz-placeholder { /* Firefox 19+ */
				  color: rgba(#eeeeee, 0.3);
				  font-size: 40px;
				}

				:-ms-input-placeholder { /* IE 10+ */
				  color: rgba(#eeeeee, 0.3);
				  font-size: 40px;
				}

				:-moz-placeholder { /* Firefox 18- */
				  color: rgba(#eeeeee, 0.3);
				  font-size: 40px;
				}
			}
			.rate-wrapper {
				position: relative;

				input {
					padding-left: 40px;
					padding-right: 40px;
				}

				span {
					position: absolute;
					font-size: 40px;
					left: 10px;
				}
			}
		}

		.calculator {
			background-image: url('../../assets/img/Chefs/salary-calculator.png');
		    background-size: cover;
		    background-position: top center;
		    height: 237px;
		    margin-top: -14px;
		    padding-top: 12px;

			.total {
				max-width: 312px;
				margin: 23px auto 0px auto;
				overflow-x: hidden;

				span {
			    	font-size: 40px;
			    }
			}

			@media screen and (max-width: $screen-md) {
				margin-top: 30px;
				height: 370px;
			    padding-top: 45px;

			    .total {
			    	margin-top: 90px;
			    }
			}

			@media screen and (max-width: $screen-lg) and (min-width: $screen-md){
				height: 217px;
				padding-top: 5px;

				.total {
			    	margin-top: 13px;
			    }
			}

			@media screen and (max-width: $screen-sm) {
				background: none;
				margin-top: 20px;
				padding: 0px 30px;
				height: 250px;

				.total {
					background: none;
					border: 2px dashed #c675a3;
					border-radius: 20px;
					margin-top: 0;
					height: 80px;
					width: 100%;
					padding-top: 10px;
				}
			}
		}

		.fineprint {
			font-size: 12px;
			font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
			margin-top: -70px;

			@media screen and (max-width: $screen-md) {
				padding-left: 35px;
				padding-right: 35px;
			}
		}
	}

	.page-info {
		padding-top: 45px;
		padding-bottom: 45px;
		font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
		color: #666;

		p {
			font-size: 16px;
		}

		.quote {
			text-align: center;
			padding: 0px 40px;

			p {
				color: $brand-pink;
				font-family: 'Gibson-SemiBold';
			}

			&:before {
				content: '"';
				position: absolute;
				top: -50px;
			    left: 0px;
			    font-size: 100px;
			    color: #cccccc;
			    transform: skewX(-10deg) scaleX(-1);
			    font-weight: bolder;
			}

			&:after {
				content: '"';
				position: absolute;
				bottom: -70px;
				right: 0px;
				font-size: 100px;
			    color: #cccccc;
			    transform: skewX(-10deg);
			    font-weight: bolder;
			}

			@media screen and (max-width: $screen-md) {
				margin-top: 30px;
			}

			@media screen and (max-width: $screen-sm) {
				&:before, &:after {
					display: none;
				}

				p {
					&:before {
						content: '" ';
					}

					&:after {
						content: ' "';
					}
				}
			}
		}
	}
}
