.fancy {
	font-family: 'blackjack', sans-serif;
}

.bg-wrapper {
	background-image: url('../../assets/img/bg.jpg');
	background-size: cover;
	background-position: center;
	min-height: 100vh;
	width: 100%;
	font-family: sans-serif;
	color: white;

	.find-out-more {
		padding-bottom: 20px;
		text-shadow: none;
		a {
			color: white;
		}
		i {
			font-size: 30px;
		}
	}

	.contact-area {
		position: absolute;
		z-index: 999;
		top: 50px;
		right: 140px;
		width: 205px;
		height: 65px;

		@media screen and (max-width: 1696px) {
			right: 65px;
		}
		@media screen and (max-width: 955px) {
			right: 50%;
			transform: translatex(50%);
		}

		.social {
			color: #ffffff;
			transition: all 0.25s;
			display: inline-block;

			&:hover {
				text-decoration: none;
			}

			&.last {
				margin-right: 10px;
			}

			i {
				font-size: 28px;
			}

		}

		.contact-btn {
			border: 2px solid white;
			color: white;
			font-size: 18px;
			padding: 15px 25px 15px 25px;
			border-radius: 32px;
			transition: all 0.25s;

			&:hover {
				text-decoration: none;
				background-color: rgba(0, 0, 0, 0.8);
			}
		}
	}

	img {
		display: block;
		width: 380px;
		margin: 90px auto 40px auto;
		@media screen and (max-width: 992px) {
			margin: 90px auto 30px auto;
			font-size: 25px;
		}
		@media screen and (max-width: 785px) {
			margin: 120px auto 20px auto;
		}
		@media screen and (max-width: 641px) {
			margin: 120px auto 40px auto;
		}
		@media screen and (max-width: 420px) {
			margin: 120px auto 0px auto;
		}
		@media screen and (max-width: 400px) {
			width: 310px;
		}
		@media screen and (max-width: 328px) {
			width: 270px;
		}
	}
	.main-text-container {
		text-align: center;
		font-family: 'Gibson-SemiBold', sans-serif;
		font-weight: 700;
		text-shadow: 0.5px 0 0 #000, -0.5px 0 0 #000, 0 0.5px 0 #000, 0 -0.5px 0 #000, 0.5px 0.5px #000, -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000;
		.title {
			font-size: 50px;
			@media screen and (max-width: 400px) {
				font-weight: 900;
			}
		}
		.followup {
			font-size: 40px;
			padding-bottom: 25px;
			@media screen and (max-width: 765px) {
				padding-bottom: 0;
			}
		}
		.detail {
			padding-bottom: 25px;
		}
		.info {
			font-size: 22px;
		}

	}
}

.subscribe-container {
	width: 100%;
	position: absolute;
	bottom: 15px;
	font-size: 30px;
	text-align: center;
	padding-top: 25px;
	padding-bottom: 25px;
	background-color: rgba(0, 0, 0, 0.8);
	@media screen and (max-width: 992px) {
		font-size: 25px;
	}
	@media screen and (max-height: 872px) {
		bottom: 0;
		position: relative;
		background-color: #1d1d1d;
		padding: 40px 50px;
	}
	@media screen and (max-width: 784px) {
		font-size: 22px;
		padding-top: 34px;
		bottom: 5px;
	}
	@media screen and (max-width: 704px) {
		padding-top: 15px;
		padding-bottom: 15px;
		bottom: 10px;
	}
	@media screen and (max-width: 641px) {
		bottom: 0;
		position: relative;
		background-color: #1d1d1d;
		padding: 40px 50px;
	}

	.title-of-area {
		color: #fcfcfc;
	}

	.email-holding {
		border-radius: 27px !important;
		border: none !important;
		padding: 10px 0px 10px 15px !important;
	    margin-top: 5px !important;
	    font-size: 20px !important;
		@media screen and (max-width: 364px) {
			width: 100% !important;
		}
	}

	.submit {
		border: 0;
		border-radius: 30px;
		padding: 13px 30px 13px 30px;
		margin-left: 20px;
		font-size: 18px;
		color: white;
		background: rgba(162,25,91,1);
		background: -moz-linear-gradient(left, rgba(162,25,91,1) 0%, rgba(149,26,128,1) 100%);
		background: -webkit-gradient(left top, right top, color-stop(0%, rgba(162,25,91,1)), color-stop(100%, rgba(149,26,128,1)));
		background: -webkit-linear-gradient(left, rgba(162,25,91,1) 0%, rgba(149,26,128,1) 100%);
		background: -o-linear-gradient(left, rgba(162,25,91,1) 0%, rgba(149,26,128,1) 100%);
		background: -ms-linear-gradient(left, rgba(162,25,91,1) 0%, rgba(149,26,128,1) 100%);
		background: linear-gradient(to right, rgba(162,25,91,1) 0%, rgba(149,26,128,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a2195b', endColorstr='#951a80', GradientType=1 );
		transition: all 0.5s;

		@media screen and (max-width: 992px) {
			padding: 13px 30px 13px 30px;
		}
		@media screen and (max-width: 784px) {
			padding: 13px 30px 12px 30px;
		}
		@media screen and (max-width: 505px) {
			margin-top: 10px;
			margin-left: 0;
		}

		&:hover {
			background: rgba(149,26,128,1);
			background: -moz-linear-gradient(left, rgba(149,26,128,1) 0%, rgba(162,25,91,1) 100%);
			background: -webkit-gradient(left top, right top, color-stop(0%, rgba(149,26,128,1)), color-stop(100%, rgba(162,25,91,1)));
			background: -webkit-linear-gradient(left, rgba(149,26,128,1) 0%, rgba(162,25,91,1) 100%);
			background: -o-linear-gradient(left, rgba(149,26,128,1) 0%, rgba(162,25,91,1) 100%);
			background: -ms-linear-gradient(left, rgba(149,26,128,1) 0%, rgba(162,25,91,1) 100%);
			background: linear-gradient(to right, rgba(149,26,128,1) 0%, rgba(162,25,91,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#951a80', endColorstr='#a2195b', GradientType=1 );
		}
	}
}
