
.score {
	display: inline-block;
	font-family: FontAwesome;
	font-size: 30px;
	color: #fff;
	position: relative;
	padding-left: 0px;
	overflow: hidden;

	&:before, span:before{

		content: "\f005\f005\f005\f005\f005";
		display: block;
		letter-spacing: 3px;
	}

	span {
		color: $brand-pink;
		position: absolute;
		top: 0;
		left: 0;
		overflow: hidden;
	}
}
