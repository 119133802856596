
.disputes {
    color: #666;
    background-color: #d1d1d1;
    border-radius: 60px;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 30px;

    .row:not(:last-child):not(:only-child){
        margin-bottom: 10px;

    }
}
