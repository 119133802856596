input {
	&.email {
		background-color: white;
		width: 100%;
		height: 40px;
		border-radius: 30px;
		border: none;
		padding-left: 20px;
	}

	&.password {
		background-color: white;
		width: 100%;
		height: 40px;
		border-radius: 30px;
		border: none;
		padding-left: 20px;
	}

	&.text {
		background-color: white;
		width: 100%;
		height: 40px;
		border-radius: 30px;
		border: none;
		padding-left: 20px;
	}
}

textarea{
	&.text {
		background-color: white;
		width: 100%;
		border-radius: 25px;
		border: none;
		padding-left: 20px;
		padding-right: 20px;
	}
}

.select-white{
	display: inline-block;

	&.select-small{
		width: 85px;
	}

	&.select-full{
		width: 100%;
	}

	select {
		background: url('../../assets/img/dropdown-arrow.png') no-repeat 80% #fff;
		background-position: right 20px center;
		-moz-appearance: window;
    	-webkit-appearance: none;
		background-size: 12px;
		background-color: white;
		height: 40px;
		width: 100%;
		border: none;
		border-radius: 20px;
		color: #666;
		font-size: 15px;
		padding-left: 10px;

		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
	}
}
