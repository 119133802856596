.colour-overlay {
	width: 100%;
	height: 1000px;
	background-color: #333333;
	min-height: 90vh;
}
.decline-wrapper {
	display: none;
}
.reviews-button {
	position: absolute;
	top: 200px;
}
.reviews-wrapper {
	display: none;
}
.review-lightbox {
	height: auto;
	width: 800px;
	background-color: white;
	margin: 10px auto 0px auto;
	padding: 5px 30px 15px 30px;

	h3 {
		text-transform: lowercase;
		color: $brand-pink;
		span {
			color: #666666;

		}
	}
	.score {
		color: #d1d1d1;
	}
	.close {
		button {
			border: none;
			color: $brand-pink;
			font-size: 20px;
		}
	}
	.review-pagination {

		margin-top: 10px;
		padding: 4px;
		text-align: center;
	}
	.review {
		background-color: #d1d1d1;
		border-radius: 20px;
		margin-top: 10px;
		padding: 20px;

		.inner-review {
			background-color: white;
			border-radius: 15px;
			width: 60%;
			margin-left: 10px;
			.review-title {
				font-size: 20px;
				color: $brand-pink;
				font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;
			}
			.review-content {
				font-size: 13px;
				color: #666666;
				line-height: 15px;
				font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
				margin-bottom: 10px;

			}
			.reviewer {
				font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;
				font-size: 13px;
				color: #666666;
				text-transform: lowercase;
			}
		}
		.star-rating {
			margin-bottom: 20px;
			margin-left: 5px;

			.score {
				color: white;
				font-size: 14px;
			}

			p {
				font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;
				font-size: 12px;
				color: #666666;
				margin-bottom: 0px;
			}
		}
	}
	@media screen and (max-width: $screen-md) {
		width: 100%;
		.review {
			.inner-review {
				width: 97%;
				margin-bottom: 20px;
			}
		}
		.star-rating {
			text-align: center;
			.stars {
				i {
					font-size: 25px;
				}
			}
		}
	}
}

.decline-lightbox {
	background-color: white;
	padding: 0 20px;
	font-size: 14px;
	color: $dark-grey;

	h4 {
		color: $brand-pink;
		font-size: 24px;
		font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;
		margin-bottom: 35px;
	}

	textarea{
		margin-bottom: 10px;
	}

	.reason-for-declining {
		background-color: #d1d1d1;
		border-radius: 60px;
		height: 60px;
		width: 100%;
		padding: 10px;

		.cancel, .where, .length, .reason, button {
			float: left;
		}
		.length {
			.text {
				float: right;
			}
		}
		.where {
			margin: -3px 0px 0px 10px;
			min-width: 140px;
			p {
				margin-bottom: 0;
				color: #666;
				font-size: 17px;
				&.location {
					font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;
				}
			}
		}
		.length {
			.text {
				margin: 7px 0px 0px 10px;
				min-width: 140px;
				p {
					font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;
					color: #666666;
					font-size: 17px;
				}
			}
		}
		.reason {
			select {
				background: url('../../assets/img/dropdown-arrow.png') no-repeat 95% #fff;
				background-size: 12px;
				background-color: white;
				float: right;
				margin-right: 10px;
				font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
				height: 40px;
				width: 300px;
				border: none;
				border-radius: 20px;
				color: #666;
				font-size: 15px;
				padding-left: 15px;
			}

			//HIDE DEFAULT ARROW
			select::-ms-expand { display: none; }
			select{
			    -webkit-appearance: none;
			    appearance: none;
			}
		}
		button.btn-decline, button.btn-dispute{
			@include gradient(#ef1616, #d21c1e);
			width: 160px;
			height: 40px;
			color: white;
			text-align: left;
			font-size: 18px;
			float: right;
			i {
				float: right;
				//vertically center it
				margin-top: 3px;
			}
			&:hover {
				background-color: #ef1616;
			}
		}
		a.btn-normal, button.btn-normal {
			background-color: #fff;
			width: 160px;
			height: 40px;
			text-align: left;
			color: $dark-grey;
			font-size: 18px;
			float: right;
			margin-right: 10px;
			i {
				float: right;
				//vertically center it
				margin-top: 3px;
			}
			&:hover {
				background-color: #eee;
			}
		}

	}
	@media screen and (max-width: $screen-lg) {
		.cancel, .where, .length, .reason, button, .length .icon-bubble {
			float: none !important;
		}
		.icon-bubble {
			margin: 0 auto;
		}

		.reason-for-declining {
			height: auto;
			text-align: center;
			.where {
				margin-left: 0;
				margin-top: 5px;
			}
			.length {
				.icon-bubble {
					margin-top: 10px;
				}
				.text {
					float: none !important;
				}
			}
			.reason {
				select {
					float: none !important;
					display: block;
					margin: 0 auto;
				}
			}
			button.btn-decline {
				margin: 10px auto;
			}
		}
	}
	@media screen and (max-width: 420px) {
		.reason-for-declining {
			.reason {
				select {
					width: 100%;
				}
			}
		}
	}
}

/* availability Fancy Box */
.availability-select {

	padding: 0 20px;
	font-size: 14px;

	@media (min-width: $screen-sm-min) {
		width: $container-sm;
	}

	@media (min-width: $screen-md-min) {
   		width: 750px;
	}

	@media (min-width: $screen-lg-min) {
   		width: 950px;
	}

	.availability-select-title{
		margin-top: 0;
		font-size: 26px;
		color: $brand-pink;
		span{
			color: #666666;
		}
	}

	.form-group-box{
		p{
			line-height: 40px;
		}

		label{
			padding: 0;

			.icon {
				display: inline-block;
				float: left;
				height: 40px;
				width: 40px;
				border-radius: 50%;
				background-color: white;
				text-align: center;
				padding-top: 7px;
				margin-right: 10px;
				img {
					width: 50%;
				}
			}
			.text{
				padding-top: 3px;
				strong{
					width: 100%;
					font-weight: 700;
				}
				&.no-icon{
					margin-left: 50px;
					display: block;
				}
			}
		}
	}
}

.admin_booking-fancybox{

	.tube{
		background-color: #d1d1d1;
		border-radius: 60px;

		width: 100%;
		padding: 10px;
	}

}

#edit_shift_form{

	.form-group{
		.row{
			margin-bottom: 10px;
			color: $dark-grey;
		}
	}

}

/* Fancy Box Custom */
.fancybox-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    z-index: 8040;
}

.job-review{
	font-size: 14px;
	color: #666666;
}
