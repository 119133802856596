
/* DatePicker Container */


.datepicker-filter {
	width: 216px;
	height: auto;
	margin: 5px auto 0;
	font: 9pt Arial, sans-serif;
	background-color: #d1d1d1;
	position: relative;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	padding-bottom: 5px;

	a {
		text-decoration: none;
		display: block;
		text-align: center;
		color: #666666;
	}

	table {
		width: 100%;

	}

	thead {
		background-color: #f7f7f7;
		border-bottom: 1px solid #bbb;
	}
	th {
		text-transform: uppercase;
		font-size: 6pt;
		padding: 5px 0;
		color: #666666;
		text-align: center;
	}
	tbody td {
		padding: 0;
		border-right: 1px solid #bbb;
		text-align: center;
	}

	tbody td:last-child {
		border-right: 0px;
	}

	tbody tr {
		border-bottom: 1px solid #bbb;
	}

	tbody tr:last-child {
		border-bottom: 0px;
	}
	td span, td a {
		display: inline-block;
		font-weight: bold;
		text-align: center;
		width: 30px;
		height: 30px;
		line-height: 30px;
		color: #666666;
	}

	.ui-datepicker-header {

	    color: #666666;
	    height: auto;
	    padding: 7px 15px 10px 15px;
		line-height: 30px;
	}
	.ui-datepicker-title {
		text-align: center;
	}
	.ui-datepicker-prev, .ui-datepicker-next {
		display: inline-block;
		width: 20px;
		height: 30px;
		text-align: center;
		cursor: pointer;
		overflow: hidden;
		&:before{
			font-family: FontAwesome;
			color: #fff;
			font-size: 20px;
		}
		.ui-icon{
			display:none;
		}
	}

	.ui-datepicker-prev {
		float: left;
	}
	.ui-datepicker-next{
		float: right;
	}
	.ui-datepicker-prev:before {
		float: left;
		content: "\f104";
	}
	.ui-datepicker-next:before {
		float: right;
		content: "\f105";
	}

	.ui-datepicker-calendar .ui-state-default {
		background: #ededed;
	}
	.ui-datepicker-calendar .ui-state-hover {
		background: #f7f7f7;
	}
	.ui-datepicker-calendar .ui-state-active {
		background: $brand-pink;
		color: #fff;
		border: 1px solid #55838f;
		position: relative;
		margin: -1px;
	}
	.ui-datepicker-unselectable .ui-state-default {
		background: #f4f4f4;
		color: #b4b3b3;
	}
	.ui-datepicker-calendar td:first-child .ui-state-active {
		width: 29px;
		margin-left: 0;
	}
	.ui-datepicker-calendar td:last-child .ui-state-active {
		width: 29px;
		margin-right: 0;
	}
	.ui-datepicker-calendar tr:last-child .ui-state-active {
		height: 29px;
		margin-bottom: 0;
	}
}



.tooltip.in{
	opacity: 1;
}
.tooltip > .tooltip-inner {
      background-color: #fff;
      color: #666;
      border: 1px solid #fff;
      padding: 3px 10px;
      font-size: 12px;
      box-shadow: rgba(0, 0, 0, 0.3) 0 2px 10px;
      border-radius: 15px;
      span{
      	color: $brand-pink;
      	font-weight: bold;
      }
  }
  /* Tooltip on top */
.tooltip.top > .tooltip-arrow {
      border-top: 5px solid #fff;
      box-shadow: rgba(0, 0, 0, 0.3) 0 2px 10px;
  }

#calendar{

	/* DatePicker Container */
	.ui-datepicker {
		width: 100%;
		height: auto;
		margin: 5px auto 0;
		border: 10px solid #fff;
		font: 12pt Arial, sans-serif;
	}
	.ui-datepicker a {
		text-decoration: none;
	}
	/* DatePicker Table */
	.ui-datepicker table {
		width: 100%;
	}
	.ui-datepicker-header {
		background: #a1a1a1;
		color: #fff;
		font-weight: bold;
		line-height: 40px;
	}
	.ui-datepicker-title {
		text-align: center;
		text-transform: lowercase;
		border-bottom: 5px solid #fff;
	}
	.ui-datepicker-prev, .ui-datepicker-next {
		display: inline-block;
		width: 30px;
		height: 30px;
		text-align: center;
		cursor: pointer;
		overflow: hidden;
		&:before{
			font-family: FontAwesome;
			color: #fff;
			font-size: 20px;
		}
		.ui-icon{
			display:none;
		}
	}
	.ui-datepicker-next:before {
		content: "\f105";
	}
	.ui-datepicker-prev:before {
		content: "\f104";
	}

	.ui-datepicker-prev {
		float: left;
		background-position: center -30px;
	}
	.ui-datepicker-next {
		float: right;
		background-position: center 0px;
	}
	.ui-datepicker thead {
		background-color: #f7f7f7;
		border-bottom: 5px solid #fff;
	}
	.ui-datepicker th {
		text-transform: lowercase;
		font-weight: normal;
		text-align: center;
		padding: 5px 0;
		color: #a1a1a1;
		width: 14%;
	}
	.ui-datepicker th span {
		display: block;
		border: 1px solid #a1a1a1;
		color: #a1a1a1;
		line-height: 40px;
	}
	.ui-datepicker tbody td, .ui-datepicker th {
		padding: 0;
		border-right: 5px solid #fff;
	}
	.ui-datepicker tbody td:last-child , .ui-datepicker th:last-child {
		border-right: 0px;
	}
	.ui-datepicker tbody tr {
		border-bottom: 5px solid #fff;
	}
	.ui-datepicker tbody tr:last-child {
		border-bottom: 0px;
	}
	.ui-datepicker td span, .ui-datepicker td a {
		display: inline-block;
		text-align: center;
		height: 40px;
		line-height: 40px;
		color: #666666;
	}
	.ui-datepicker-calendar .ui-state-default {
		background: #e1e1e1;
		width: 100%;
	}
	.ui-datepicker-calendar .ui-state-hover {
		background: #f7f7f7;
	}
	.ui-datepicker-calendar .ui-state-active {
		background: #d1cfcf;
	}
	.ui-datepicker-calendar :not(.ui-datepicker-other-month).ui-day-available,
	.ui-datepicker-calendar :not(.ui-datepicker-other-month).ui-day-available .ui-state-default,
	.ui-datepicker-calendar :not(.ui-datepicker-other-month).ui-day-available .ui-state-active {
		// background: #6eafbf;
		color: #fff;
		background-color: #ffa800;
	}
	.ui-datepicker-calendar :not(.ui-datepicker-other-month).ui-day-booked,
	.ui-datepicker-calendar :not(.ui-datepicker-other-month).ui-day-booked .ui-state-default,
	.ui-datepicker-calendar :not(.ui-datepicker-other-month).ui-day-booked .ui-state-active {
		// background: #6eafbf;
		color: #fff;
		@include gradient(#8cc63f, #65ad05);
	}
	.ui-datepicker-calendar :not(.ui-datepicker-other-month).ui-day-chef_unavailable,
	.ui-datepicker-calendar :not(.ui-datepicker-other-month).ui-day-chef_unavailable .ui-state-default,
	.ui-datepicker-calendar :not(.ui-datepicker-other-month).ui-day-chef_unavailable .ui-state-active {
		// background: #6eafbf;
		color: #fff;
		background-color: #9e0b0f;
	}
	.ui-datepicker-calendar :not(.ui-datepicker-other-month).ui-day-chef_available,
	.ui-datepicker-calendar :not(.ui-datepicker-other-month).ui-day-chef_available .ui-state-default,
	.ui-datepicker-calendar :not(.ui-datepicker-other-month).ui-day-chef_available .ui-state-active {
		// background: #6eafbf;
		color: #fff;
		@include gradient(#8cc63f, #65ad05);
	}
	.ui-datepicker-calendar :not(.ui-datepicker-other-month).ui-day-chef_available_restricted,
	.ui-datepicker-calendar :not(.ui-datepicker-other-month).ui-day-chef_available_restricted .ui-state-default,
	.ui-datepicker-calendar :not(.ui-datepicker-other-month).ui-day-chef_available_restricted .ui-state-active {
		// background: #6eafbf;
		color: #fff;
		background-color: #5c9f03;
	}
	.ui-datepicker-calendar :not(.ui-datepicker-other-month).ui-day-chef_booked,
	.ui-datepicker-calendar :not(.ui-datepicker-other-month).ui-day-chef_booked .ui-state-default,
	.ui-datepicker-calendar :not(.ui-datepicker-other-month).ui-day-chef_booked .ui-state-active {
		// background: #6eafbf;
		color: #fff;
		@include gradient(#8cc63f, #65ad05);
	}
	.ui-datepicker-calendar :not(.ui-datepicker-other-month).ui-day-chef_selected,
	.ui-datepicker-calendar :not(.ui-datepicker-other-month).ui-day-chef_selected .ui-state-default,
	.ui-datepicker-calendar :not(.ui-datepicker-other-month).ui-day-chef_selected .ui-state-active {
		// background: #6eafbf;
		color: #fff;
		@include gradient();
	}
	.ui-datepicker-unselectable .ui-state-default {
		background: #f4f4f4;
		color: #b4b3b3;
	}
	.ui-datepicker-calendar td:first-child .ui-state-active {

		margin-left: 0;
	}
	.ui-datepicker-calendar td:last-child .ui-state-active {

		margin-right: 0;
	}
	.ui-datepicker-calendar tr:last-child .ui-state-active {

		margin-bottom: 0;
	}

}
