.job{
	.details-box{

		font-size: 16px;
		line-height: 18px;
		background-color: #d1d1d1;
		border-radius: 60px;
		width: 100%;
		min-height: 60px;
		margin-bottom: 10px;
		padding: 0;
		color: #666;

		@media screen and (max-width: $screen-md) {
			height: auto;
		}

		&.details-box-job{
			@media screen and (min-width: $screen-md) and (max-width: $screen-lg){

				padding: 10px;

				.btn.btn-details {
					clear: both;
				}
			}
		}

		&.shift{
			background-color: #d8d8d8;
		}

		@media screen and (max-width: $screen-md) {
			text-align: center;

			.icon-bubble {
				margin-left: 50px !important;
			}

			.details-wrapper {
				.pull-right {
					float: none !important;
				}
			}
		}

		p {
			font-size: 16px;
			font-family: $font-family-regular;
			margin: 10px;

			span{
				font-family: $font-family-semi-bold;
			}
		}

		a:not(.btn) {
			color: #666;
		}

		.extra-info{
			margin: 0px 70px 0px 70px;
			padding: 20px 0 30px 0;
			h2, h3 {
				margin-top: 10px;
				font-size: 18px;
			}
			font-weight: normal;
		}

		.btn {
		    min-width: 110px;
		    line-height: 25px;
		    font-size: 14px;
		    margin: 10px 10px 10px 5px;

			&.dispute-btn {
				color: white;
				width: 140px;
			}

		    &.btn-review {
		    	width: 130px;
		    	padding-top: 10px;

		    	img {
		    		float: none;
					margin-top: -5px;
					margin-left: 10px;
		    	}
		    }
		}

		.icon-bubble {
		    float: left;
		    height: 40px;
		    width: 40px;
		    border-radius: 50%;
		    background-color: white;
		    text-align: center;
		    padding-top: 7px;
		    margin: 10px;
		}
		.single-line-text{
			line-height: 40px;
		}
	}

}

