.legal {

	/*
	header {
		.navbar-nav > li {
			margin-top: 20px;
		}

		&.sticky {
			background-color: grey;

			.navbar-nav > li {
				margin-top: 25px;
			}

			.navbar-brand {
				@media screen and (max-width: $screen-sm) {
					padding-top: 30px !important;
				}
			}
		}

		.navbar-toggle {
			@media screen and (max-width: $screen-sm) {
				margin-top: 25px;
				margin-bottom: 10px;
			}
		}

		@media screen and (max-width: $screen-sm) {
			left: 0;
			right: 0;
		}
	}

	.navbar {
		background-color: grey;
		height: 80px;
		min-height: 80px;
	}
*/

	.inner {
		padding-left: 30px;

		&.twice {
			padding-left: 60px;
		}
	}

	.push {
		//padding: 150px 0px;
	}

	.navbar-brand {
		padding-top: 10px;
		padding-bottom: 0;
		height: 80px;

		img {
			width: 200px;
		}

		@media screen and (max-width: $screen-sm) {
			img {
				width: 140px !important;
			}
		}
	}

	table {
		tr {
			border-bottom: 1px solid #e8e8e8;

			&> td {
				padding: 20px 0px;
			}

			td {
				vertical-align: top;
				margin-bottom: 10px;

				&:first-of-type {
					width: 25%;
				}
			}
		}
	}

	@media screen and (max-width: $screen-sm) {
		p, h2, h3 {
			padding: 0px 10px;
		}

		table {
			margin: 0px 10px;
		}
	}
}