//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination {
  font-size: 12px;
  border-radius: 30px;

  > li {

    > a {
      background-color: white;
    }

    &:first-child {
      > a,
      > span {
        margin-left: 0;
        @include border-left-radius(30px);
      }
    }
    &:last-child {
      > a,
      > span {
        @include border-right-radius(30px);
      }
    }
  }
}
