.login {
	font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;

	.hero {
		background-image: url('../../assets/img/login-register/nav-bg.png');
		background-position: top center;
		background-size: cover;
		height: 190px;

		.middle {
			h2 {
				margin-top: 80px;
			}
		}
		@media screen and (max-height: 690px) {
			.middle {
				h2 {
					font-size: 26px;
				}
			}
		}
		@media screen and (max-width: 1500px) {
			.middle {
				h2 {
					font-size: 40px;
				}
			}
		}
		@media screen and (max-width: 944px) {
			.middle {
				h2 {
					font-size: 26px;
				}
			}
		}
	}

	.login-register {
		background-image: url('../../assets/img/login-register/hero-bg.png');
		background-size: cover;
		background-repeat: none;
		background-position: bottom center;
		padding-bottom: 175px;
		position: relative;

		@media screen and (max-width: $screen-sm) {
			background-position: bottom right;
		}

		.form-group {
			position: relative;
		}

		input {
			font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;

			@media screen and (max-width: $screen-md) {
				border: 1px solid rgba(black, 0.5);
			}
		}

		.register {
			h2 {
				color: $brand-pink;
				font-size: 36px;
				margin-top: 43px;
				margin-bottom: 25px;
			}

			p {
				color: #666;
				font-size: 17px;
				font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
				margin-bottom: 33px;
				max-height: 50px;
			}

			@media screen and (max-width: $screen-lg) {
				h2 {
					margin: 10px 0px;
					font-size: 32px;
				}
			}

			@media screen and (max-width: $screen-md) {
				h2 {
					margin: 30px 0px;
				}

				p {
					color: #333;
					max-height: initial;
				}
			}
		}

		.login {
			h2 {
				color: #ffffff;
				font-size: 36px;
			}

			p {
				color: #ffffff;
				font-size: 17px;
				font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
			}
		}

		.gradient {
			background: -moz-linear-gradient(left,  rgba(0,0,0,0.2) 0%, rgba(255,255,255,0) 100%);
			background: -webkit-linear-gradient(left,  rgba(0,0,0,0.2) 0%,rgba(255,255,255,0) 100%);
			background: linear-gradient(to right,  rgba(0,0,0,0.2) 0%,rgba(255,255,255,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33000000', endColorstr='#00ffffff',GradientType=1 );
			position: absolute;
			height: 470px;
			width: 936px;
			top: 225px;

			@media screen and (max-width: $screen-md) {
				top: 677px;
				width: 100%;
				display: none;
			}
			@media screen and (max-width: 667px) {
				width: 100%;
			}
		}
		.register-inputs {
			padding-top: 33px;
			border-bottom-left-radius: 30px;

			.who {
				font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
				border-radius: 30px;
				font-size: 18px;
				padding-left: 20px;
				padding-right: 20px;
				width: 100%;
				background-color: white;
				border: 0;

				&:focus {
					border: none;
					box-shadow: none;
					outline: none;
				}
				@media screen and (max-width: $screen-md) {
					border: 1px solid rgba(black, 0.5);
				}
				@media screen and (max-width: $screen-sm) {
					width: 100%;
				}
			}
			.register-area {
				border-bottom: 1px solid white;
				margin-bottom: 20px;

				.confirm-pass {
					margin-bottom: 20px;
				}
				@media screen and (max-width: 767px) {
					margin-top: 40px;
				}
				@media screen and (max-width: 751px) {
					margin-top: 0px;
				}
				@media screen and (max-width: 667px) {
					margin-top: -23px;
				}
			}

			button {
				width: 63%;
				margin-left: 9px;
				text-align: left;

				i {
					float: right;
					margin-top: 3px;
				}
			}
		}
		.reminder-inputs{
			padding-top: 53px;
			padding-bottom: 53px;
		}

		.login-inputs {
			background: -moz-linear-gradient(top,  rgba(0,0,0,0.4) 0%, rgba(255,255,255,0) 100%);
			background: -webkit-linear-gradient(top,  rgba(0,0,0,0.4) 0%,rgba(255,255,255,0) 100%);
			background: linear-gradient(to bottom,  rgba(0,0,0,0.4) 0%,rgba(255,255,255,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33000000', endColorstr='#00ffffff',GradientType=0 );
			margin-top: -1px;
			margin-left: 100px;
			padding-left: 60px;
			padding-right: 60px;

			.login-area {
				padding-top: 33px;
			}

			h2 {
				margin-top: 0px;
				margin-bottom: 25px;
				padding-top: 44px;
				font-size: 36px;
				line-height: 36px;
			}

			p {
				font-size: 16px;
				line-height: 24px;
				font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
				margin-bottom: 33px;
			}

			.checkbox {
				background-color: white;
				border-radius: 20px;

				.checkbox-label {
					font-size: 13px;
					color: #bbb;
					font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;
				}

				input {
					margin-top: 15px;
					margin-left: -5px;
				}
			}

			.forgot-password {
				a {
					color: white;
					float: right;

					@media screen and (max-width: $screen-md) {
						color: #333;
					}
				}
			}

			button {
				height: 40px;
				text-align: left;

				i {
					float: right;
					margin-top: 3px;
				}
			}

			@media screen and (max-width: $screen-lg) {
				padding-left: 30px;
				padding-right: 30px;
			}

			@media screen and (max-width: $screen-md) {
				margin-left: 0;
			}
		}
	}
}
