.employers {
	.hero, .quick-easy {
		form {
			margin-top: 80px;

			.email {
				width: 380px;
				margin-right: 20px;
				color: black;

				@media screen and (max-width: 1030px) {
					margin-bottom: 30px;
				}

				@media screen and (max-width: 450px) {
					width: 100%;
				}
			}

			a.btn-primary {
		    	font-family: 'Gibson-Regular', sans-serif;
				font-size: 24px;
				background: none;
				border: 2px solid white;
				border-radius: 30px;
				padding: 11px 35px;
				height: 40px;
				font-weight: 100;
				margin: 40px 10px;
				transition: background-color 0.5s;

				&:hover {
					background-color: rgba(0, 0, 0, 0.3);
				}

				@media screen and (max-width: $screen-md) {
					margin: 40px 5px;
				}

				@media screen and (max-width: 712px) {
					margin: 10px 0px;
					padding: 11px 60px;
				}
		    }
		}
	}

	.hero {
		background-image: url('../../assets/img/Employers/hero-bg.png');
		height: 90vh;

		button.btn-default {
			height: 40px;
			padding: 0px 20px;
			margin-top: -4px;
		}

		@media screen and (max-height: 690px) {
			height: 100vh;
		}

		.middle {
			h2 {
				@media screen and (max-height: 690px) {
					font-size: 26px;
				}
			}

			.fancy {
				@media screen and (max-height: 690px) {
					font-size: 20px;
				}
			}
		}

		.bottom {
			bottom: 0px;
		}

		.form {
			@media screen and (max-height: 690px) {
				margin-top: 30px;
			}
		}

		.faded {
			background-color: rgba(0, 0, 0, 0.4);
			width: 100%;
			padding-top: 33px;
			padding-bottom: 33px;

			img {
				width: 80px;
				float: left;
				margin-right: 22px;
			}

			p {
				font-size: 24px;
				line-height: 24px;
				margin-top: 15px;
				text-align: left;
				color: white;
			}

			@media screen and (max-width: $screen-md) {
				background: none;
			}
		}
	}

	.quick-easy {
		background-image: url('../../assets/img/Employers/quick-and-easy-bg.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: bottom center;
		font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;
		text-align: center;
		color: white;

		.title-pink {
			color: $brand-pink;
			font-size: 40px;
			margin-top: 50px;
			margin-bottom: 60px;
		}

		.box {
			background-color: rgba(0, 0, 0, 0.3);
			border-radius: 10px;
			height: 500px;
			width: 100%;
			padding: 63px 30px;
			transition: background-color 0.25s;

			&:hover {
				background-color: rgba(152,26,120, 0.8);
			}

			.box-title {
				font-size: 24px;
				margin-bottom: 40px;
			}

			.box-description {
				font-size: 15px;
				font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
				color: white;
				line-height: 24px;
			}

			.box-image {
				min-height: 146px;

				.chef-hat {
					width: calc(142px/2);
				}

				.right-chef {
					width: calc(180px/2);
					margin-top: 10px;
				}

				.card {
					width: calc(178px/2);
					margin-top: 10px;
				}

				.sit-back {
					width: calc(180px/2);
				}
			}

			//box
			@media screen and (max-width: $screen-lg) {
				margin-bottom: 20px;
			}

			@media screen and (max-width: $screen-sm) {
				height: auto;
			}
		}

		.singup-info {
			margin-bottom: 110px;

			h2 {
				margin: 55px 0px;
				font-size: 50px;
				line-height: 50px;
			}

			form {
				margin-top: 0;
			}

			button {
				height: 40px;
			}
		}

		//quick-easy
		@media screen and (max-width: 575px) {
			button {
				margin-bottom: 30px;
			}
		}

		@media screen and (max-width: $screen-sm) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	.page-info {
		padding-top: 45px;
		padding-bottom: 45px;

		p {
			font-size: 16px;
			line-height: 24px;
			font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
		}

		.quote {
			text-align: center;
			padding: 0px 40px;

			p {
				color: $brand-pink;
				font-family: 'Gibson-SemiBold';
			}

			&:before {
				content: '"';
				position: absolute;
				top: -50px;
			    left: 0px;
			    font-size: 100px;
			    color: #cccccc;
			    transform: skewX(-10deg) scaleX(-1);
			    font-weight: bolder;
			    font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
			}

			&:after {
				content: '"';
				position: absolute;
				bottom: -70px;
				right: 0px;
				font-size: 100px;
			    color: #cccccc;
			    transform: skewX(-10deg);
			    font-weight: bolder;
			    font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
			}

			@media screen and (max-width: $screen-md) {
				margin-top: 30px;
			}

			@media screen and (max-width: $screen-sm) {
				&:after {
					right: 25px;
				}
			}
		}
	}
}
