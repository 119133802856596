.btn {
	border-radius: 30px;
	transition: background-color 0.5s;
	font-family: 'Gibson-SemiBold', sans-serif;

	&.btn-primary {
		font-size: 18px;
		background: none;
		border: 2px solid white;
		border-radius: 30px;
		transition: background-color 0.5s;
		padding-top: 3px;

		&:hover {
			background-color: rgba(0, 0, 0, 0.3);
		}

		&:active {
  			color: white;
  		}
	}

	&.btn-default {
		font-size: 18px;
		@include gradient();
		background-size: 100%;
		border-radius: 30px;
		border: 0;
		color: white;
		transition: background-color .8s;

  		display: inline-block;
  		position: relative;

  		&:active {
  			color: white;
  		}

  		/*
  		z-index: 100;

		&:before {
			@include gradientReverse();
			content: '';
			position: absolute;
		    top: 0;
		    left: 0;
		    opacity: 0;
		    height: 100%;
    		width: 100%;
    		z-index: -100;
    		transition: opacity 1s;
    		border-radius: inherit;
		    display: block;
		    position: absolute;
		    z-index: -100;
		}
		*/
		&:hover {
			&:before {
		      opacity: 1;
		    }
		}
	}

	&:focus {
		outline: none !important;
	}

	&.btn-medium-font {
		font-size: 16px;
	}

	&.btn-delete{
		font-family: FontAwesome;
		display: inline-block;
	    color: #fff;
	    background-color: #555555;
	    font-size: 22px;
	    padding: 4px 10px;
	    vertical-align: middle;
	    text-align: center;
	    margin-left: 5px;
	    &:hover{
	    	@include gradient();
	    }
	}

	&.btn-details {
		background-color: #fff;
		color: #666;
		margin: 0px 10px 0px 10px;
		background: url('../../assets/img/dropdown-arrow.png') no-repeat 50% #fff;
		background-size: 20px 10px;
		background-position: right 4px center;
		text-align: left;
		padding-left: 15px;

		&:hover {
			background-color: #d1cfd0;
		}
	}

	&.btn-edit-shift {
		background-color: #d1cfd0;
		color: #666;
		margin: 0px 10px 0px 10px;
		text-align: left;
		padding-left: 15px;

		&:hover {
			background-color: #fff;
		}
	}

	&.btn-accept, &.btn-review {
		text-align: left;
		color: white;
		font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;
		transition: all .5s;

		@include gradient(#8dc63e, #6aae0d);
		img {
			width: 20px;
		}
		&:hover {
			@include gradientReverse(#6aae0d, #8dc63e);
		}
	}

	&.btn-decline , &.btn-dispute {
		text-align: left;
		color: white;
		font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;
		transition: all .5s;

		@include gradient(#f01616, #d12525);
		margin: 0px 10px 0px 10px;

		&:hover {
			@include gradientReverse(#d12525, #f01616);
		}
	}

	img {
		float: right;
		height: 15px;
		width: 15px;
		margin-top: 3px;
	}

}





