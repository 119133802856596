
/* Dashbaord General */
.dashbaord-content {
	font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;
    background-color: #e1e1e1;
    width: 100%;

	/* Dashbaord menu */
	.dashbaord-menu {
	    background-color: #ebebeb;
	    padding: 22px 10px;
	    .dashbaord-title {
	        @include gradient();
	        height: 40px;
	        border-radius: 20px;
	        padding: 7px 15px 0px 15px;
	        p {
	            font-size: 18px;
	            color: white;
	        }
	        h2 {
	            font-size: 18px;
	            color: white;
	        }
	    }
	}
}
