header {
	position: absolute;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    transition: all 0.25s;

	&.navbar-fixed-top.sticky {
		.navbar-nav {
			margin-top: 24px;
		}
	}

	&.navbar-fixed-top {
		.navbar-collapse {
			@media screen and (max-width: $screen-md) {
				max-height: initial;
				padding: 0;
			}
		}
	}

    &.sticky {
    	background-color: rgba(0, 0, 0, 0.5);
    	height: 100px;

		.navbar {
			@media screen and (max-width: $screen-sm) {
				min-height: 0;
			}
		}

    	.navbar-nav > li {
			margin-top: 2px;
		}
    	.navbar-brand {
    		padding-top: 0;

    		img {
    			width: 300px;
    			padding-top: 7px;
    			@media screen and (max-width: $screen-sm) {
					width: 250px;
    				padding-top: 15px;
    				margin-top: 0;
				}
				@media screen and (max-width: 328px) {
					width: 180px;
					padding-top: 25px;
				}
				@media screen and (max-width: 254px) {
					width: 120px;
					padding-top: 33px;
				}
    		}

    		@media screen and (max-width: $screen-md) {
    			height: 65px;
				padding-bottom: 0;
				padding-top: 5px;
			}
    	}
    	.navbar-nav > li > a {
    		padding-top: 35px;
    		padding-bottom: 35px;
    	}
    }

	@media screen and (min-width: $screen-md) {
		.border {
			border: 2px solid white;
			border-radius: 30px;
			margin-right: 10px;
		}
		.second {
			margin-right: 0;
		}
		.navbar-nav > li {
			margin-top: 55px;
		}
	}

	.navbar-default {
		border: 0;
		background: none;
	}

	.navbar-header {
		margin-left: 5% !important;

		@media screen and (max-width: 1520px) {
			.navbar-brand {
				img {
					width: 200px !important;
					padding-top: 20px;
				}
			}
		}

		@media screen and (max-width: 1370px) {
			.navbar-brand {
				img {
					width: 150px !important;
					padding-top: 30px;
				}
			}
		}

		@media screen and (max-width: $screen-md) {
			margin-left: 0px !important;

			.navbar-brand {
				img {
					display: block;
					padding-top: 25px;
				}
			}
		}
	}

	ul.nav {
		margin-right: 5% !important;

		@media screen and (max-width: 1310px) {
			margin-right: 0 !important;
		}
	}

	.navbar-brand {
		padding-top: 25px;
		@media screen and (max-width: $screen-sm) {
			padding-top: 0;
		}

		img {
			width: 340px;
		}
	}

	.navbar-toggle {
		border: 1px solid white;
		margin: 30px 10px;

		.icon-bar {
			background-color: white !important;
		}
	}

    .navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
        @include gradient();
    }

	ul {
		li {
			font-family: 'Gibson-SemiBold', sans-serif;
			font-size: 18px;
		}

		@media screen and (max-width: $screen-md) {
            @include gradient();
			margin-top: 0 !important;
			text-align: center;
			li a {
				padding-top: 20px !important;
				padding-bottom: 20px !important;
			}
		}
	}

	.navbar-default .navbar-nav > li > a {
		color: white;
		transition: color 0.25s;
		padding-top: 10px;
		padding-bottom: 10px;

		&:hover {
			color: darken(white, 20%)
		}
	}

	@media screen and (max-width: 1240px) {
		.nav > li > a {
			padding: 10px 10px;
		}
	}

	@media screen and (max-width: 1050px) {
		.nav > li > a {
			padding: 10px 5px;
		}
	}

	@media screen and (max-width: $screen-sm) {
		left: 40px;
    	right: 40px;
	}
	@media screen and (max-width: 401px) {
		left: 0;
    	right: 0;
	}
}
