.home {
	.hero {
		background-image: url('../../assets/img/home/hero-bg.jpg');
		background-attachment: fixed;
		height: 100vh;

		.middle {
			position: absolute;
			top: 50%;
			left: 50%;
		    transform: translate(-50%, -50%);
		    color: white;

		    h2 {
		    	font-size: 50px;

		    	@media screen and (max-width: 520px) {
					font-size: 36px;
				}
		    }

		    p.more-info {
		    	font-size: 18px;
		    	font-weight: 100;
		    	font-family: 'Gibson-Regular', sans-serif;
		    	color: white;
		    }

		    a.btn-primary {
		    	font-family: 'Gibson-Regular', sans-serif;
				font-size: 24px;
				background: none;
				border: 2px solid white;
				border-radius: 30px;
				padding: 11px 35px;
				font-weight: 100;
				margin: 40px 10px;
				transition: background-color 0.5s;

				&:hover {
					background-color: rgba(0, 0, 0, 0.3);
				}

				@media screen and (max-width: $screen-md) {
					margin: 40px 5px;
					display: block;
				}

				@media screen and (max-width: 712px) {
					margin: 10px 0px;
					padding: 11px 60px;
				}
		    }

		    .fancy {
		    	font-size: 36px;

		    	@media screen and (max-width: 542px) {
					font-size: 25px;
				}
		    }
		}

		.bottom {
			position: absolute;
			bottom: 20px;
			left: 50%;
			color: white;
			animation: bounce 2s infinite;
			-webkit-animation: bounce 2s infinite;
			-moz-animation: bounce 2s infinite;
			-o-animation: bounce 2s infinite;
			transform: translateX(-50%);

			p {
				font-size: 18px;
				color: white;
			}

			i {
				font-size: 70px;
			}

			a {
				color: white;
			}

			@media screen and (max-height: 630px) {
				p {
					font-size: 14px;
				}

				i {
					font-size: 40px;
				}
			}
		}
	}

	a.anchor {
	    display: block;
	    position: relative;
	    top: -100px;
	    visibility: hidden;
	}

	.how-it-works {
		font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;
		text-align: center;

		.title {
			h2 {
				color: #cccccc;
				font-size: 40px;
				margin-top: 45px;
				margin-bottom: 35px;
			}

			img {
				width: 200px;
				margin-bottom: 35px;
			}
		}

		.followup {
			p {
				color: #666666;
				font-size: 18px;
				margin-bottom: 50px;
				line-height: 30px;
			}
		}

		p {
			font-family:'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
		}

		.box {
			.box-title {
				font-size: 24px;
				color: $brand-pink;
				font-family:'Gibson-SemiBold', 'Gibson-Regular',  sans-serif;
				margin-bottom: 25px;
			}

			.box-description {
				color: #666666;
				font-size: 15px;
				margin-bottom: 75px;
				line-height: 24px;
			}

			img {
				max-height: 90px;
				margin-bottom: 20px;
				opacity: 0;
			}

			img.pointer {
				width: calc(165px / 2);
				height: calc(180px / 2);
			}

			img.laptop {
				width: calc(240px / 2);
			}

			img.sit-back {
				width: calc(180px / 2);
				height: calc(180px / 2);
			}
		}

		.laptop-container {
			img {
				width: 100%;
			}
		}
	}

	.be-your-own-boss {
		background-image: url('../../assets/img/home/own-boss-bg.jpg');
		background-size: cover;
		background-position: bottom center;
		background-repeat: no-repeat;
		font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;
		text-align: center;
		color: white;
		min-height: 750px;

		@media screen and (max-width: $screen-md) {
			padding-bottom: 65px;
		}

		.title {
			margin-top: 45px;
			margin-bottom: 45px;

			h2 {
				font-size: 40px;
			}
		}

		.followup {
			font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
			margin-bottom: 90px;
			line-height: 30px;
			font-size: 15px;

			p {
				line-height: 30px;
				font-size: 18px;
				color: white;
			}
		}

		.benefits {
			text-align: right;
			margin-right: 45px;

			@media screen and (max-width: $screen-md) {
				text-align: center;
			}

			ul {
				li {
					font-size: 17px;
					list-style: none;
					opacity: 0;
					font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;

					img {
						height: 40px;
						margin: 7px 0px 8px 15px;
					}

					@media screen and (max-width: 385px) {
						margin-bottom: 20px;

						img {
							display: none;
						}
					}
				}
			}
		}

		.sign-up {
			text-align: left;
			margin-left: 45px;

			@media screen and (max-width: $screen-md) {
				text-align: center;
				margin-left: 0;
			}

			h4 {
				font-size: 30px;
				margin-top: 0px;
				margin-bottom: 30px;
			}

			input {
				max-width: 380px;
				color: black;
			}

			button {
				margin-top: 30px;
				font-size: 18px;
				font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;
				padding: 8px 17px;
			}
		}
	}
	.like-to-do {
		text-align: center;
		font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;
		padding-bottom: 32px;
		background: linear-gradient(#30282A, #462137, #6A0E4E);

		.title {
			h2 {
				color: #cccccc;
				font-size: 40px;
				margin-top: 45px;
				margin-bottom: 35px;
			}

			img {
				width: 200px;
				margin-bottom: 35px;
			}
		}

		.select-an-option {
			width: 265px;
		}

		.options {
			text-align: center;

			ul {
				padding-left: 0;

				li {
					list-style: none;

					a {
						display: block;
						margin-bottom: 20px;
						padding-top: 15px;
						height: 60px;
						border-radius: 30px;
						text-align: center;
						font-size: 21px;
						font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
						transition: all 0.25s;

						border: 3px solid #fff;
						background: transparent;

						&:hover {
							text-decoration: none;
						}

						@media screen and (max-width: 300px) {
							font-size: 18px;
						}
					}

					@media screen and (min-width: 800px) {
						a:hover {
							transform: translateX(20px);
						}
					}
				}
			}
		}
	}

	@-webkit-keyframes bounce {
		0%, 20%, 50%, 80%, 100% {
			-webkit-transform: translate(-50%, 0);
		}
		60% {
			-webkit-transform: translate(-50%, -15px);
		}
	}
	@-moz-keyframes bounce {
		0%, 20%, 50%, 80%, 100% {
			-moz-transform: translate(-50%, 0);
		}
		60% {
			-moz-transform: translate(-50%, -15px);
		}
	}
	@-o-keyframes bounce {
		0%, 20%, 50%, 80%, 100% {
			-o-transform: translate(-50%, 0);
		}
		60% {
			-o-transform: translate(-50%, -15px);
		}
	}
	@keyframes bounce {
		0%, 20%, 50%, 80%, 100% {
			transform: translate(-50%, 0);
		}
		60% {
			transform: translate(-50%, -15px);
		}
	}

	.booking-form {
		.modal-header {
			padding-bottom: 10px;
		}

		.p {
			margin-top: 0.75rem;
			margin-bottom: 0;
		}

		.error {
			border: solid 1px red;
		}

		.submit-group button {
			width: 100%;
			height: 40px;
			font-size: 18px;
			text-align: left;
			padding-left: 20px;
			margin: 20px 0;
			vertical-align: middle;
			touch-action: manipulation;
			cursor: pointer;
			white-space: nowrap;
			padding: 6px 12px;
			line-height: 1.428571429;
			font-family: 'Gibson-SemiBold', sans-serif;
			transition: background-color .8s;
			display: inline-block;
			position: relative;
			background-size: 100%;
			background: -webkit-linear-gradient(left, #a3195b 0%, #961b80 100%);
			background: linear-gradient(to right, #a3195b 0%, #961b80 100%);
			border-radius: 30px;
			border: 0;
			color: white;

			i {
				float: right;
				padding-top: 3px;
				padding-right: 10px;
			}

			@media screen and (max-width: $screen-md) {
				width: 50%;
				margin: 0 auto;
			}
			@media screen and (max-width: 400px) {
				width: 75%;
				margin: 0 auto;
			}
		}
	}

	.booking-form-popup {
		display: flex; 
		flex-direction: column; 
		align-items: center; 
		padding: 2rem 0;
	}
	
	.mt-2 {
		margin-top: 0.25rem;
	}
	
	.mb-5 {
		margin-bottom: 1.5rem;
	}
}
