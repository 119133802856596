.about {
	font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;

	.hero {
		background-image: url('../../assets/img/about/hero-bg.png');
		background-position: bottom center;
		background-attachment: fixed;
		height: 85vh;

		.form {
			margin-top: 25px;

			@media screen and (max-height: 690px) {
				margin-top: 30px;
			}

			form {

				.email {
					width: 380px;
					margin-right: 20px;
					color: black;

					@media screen and (max-width: 1030px) {
						margin-bottom: 30px;
					}
					@media screen and (max-width: 450px) {
						width: 100%;
					}
				}
				a.btn-primary {
			    	font-family: 'Gibson-Regular', sans-serif;
					font-size: 24px;
					background: none;
					border: 2px solid white;
					border-radius: 30px;
					padding: 11px 35px;
					height: 40px;
					font-weight: 100;
					margin: 40px 10px;
					transition: background-color 0.5s;

					&:hover {
						background-color: rgba(0, 0, 0, 0.3);
					}

					@media screen and (max-width: $screen-md) {
						margin: 40px 5px;
					}
					@media screen and (max-width: 712px) {
						margin: 10px 0px;
						padding: 11px 60px;
					}
			    }
			}
		}

		.middle {
			top: 61%;
			@media screen and (min-width: $screen-md) {
				h2 {
					margin-bottom: 50px;
				}
			}

			@media screen and (max-width: $screen-md) {
				width: 90%;
			}

		}

		@media screen and (max-height: 690px) {
			height: 100vh;

			.middle {
				top: 50%;

				h2 {
					font-size: 26px;
				}
			}
		}

		@media screen and (max-width: 1500px) {
			.middle {
				h2 {
					font-size: 40px;
				}
			}
		}
		@media screen and (max-width: 944px) {
			height: 100vh;

			.middle {
				top: 50%;

				h2 {
					font-size: 26px;
				}
			}
		}
	}

	.second-section {
		text-align: center;
		background-image: url('../../assets/img/about/exiting-times-bg.png');
		background-position: bottom center;
		background-size: cover;

		p {
			font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
			color: #666;
			font-size: 20px;
			line-height: 24px;
		}
		.title {
			color: $brand-pink;
			font-size: 40px;
			margin-top: 50px;
			margin-bottom: 55px;
		}
		.followup {
			margin-bottom: 65px;
		}
		.laptop-container-phone {
			margin-left: -90px;
			margin-bottom: -25px;

			.laptop {
				height: 450px;
				width: auto;
			}

			&.offscreen {
				margin-left: -500%;

				@media screen and (max-width: 700px) {
				    margin-left: 0;
				}
			}
			@media screen and (max-width: $screen-lg) {
				margin-bottom: 0;
				margin-left: -160px !important;
			}
			@media screen and (max-width: $screen-md) {
			    margin-left: 0px !important;
			    margin-bottom: 65px;
			}
			@media screen and (max-width: 838px) {
			    .laptop {
					width: 90%;
					height: auto;
				}
			}
		}
		.info {
			text-align: left;
			padding-left: 30px;

			img {
				height: 80px;
				margin-left: -67px;
				margin-top: 50px;
			}
			@media screen and (max-width: $screen-lg) {
				img {
					height: 60px;
				    margin-left: -40px;
				    margin-top: 0px;
				}
			}
			@media screen and (max-width: $screen-md) {
				margin-bottom: 65px;
				text-align: center;
				padding-left: 0;

			    img {
			    	display: none;
			    }
			}
		}
	}
	.extra-info {
		margin-top: 90px;
		margin-bottom: 85px;

		p {
			font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
			color: #666;
			font-size: 20px;
		}
	}
	.purple-signup {
		background-color: #a2195c;
		color: white;
		position: relative;
		margin-bottom: 50px;

		h4 {
			font-size: 30px;
			margin-top: 35px;
			margin-bottom: 25px;
		}
		p {
			font-size: 15px;
			font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
			color: #ccc;
			margin-bottom: 30px;
		}
		.form {
			margin-bottom: 35px;
			input {
				&.email-input {
					background-color: white;
					width: 380px;
					height: 40px;
					border-radius: 30px;
					border: none;
					padding-left: 20px;
					color: black;
				}
			}
			button {
				margin-left: 25px;
				height: 40px;
			}
			@media screen and (max-width: 544px) {
				width: 100%;
				text-align: center;

				input.email-input {
					width: 100%;
				}
				button {
					margin-top: 30px;
					margin-left: 0;

				}
			}
		}
		.chef {
			position: absolute;
			right: 200px;
			bottom: -51px;

			img {
				width: 320px;

				@media screen and (max-width: 1370px) {
					display: none;
				}
			}
		}

		@media screen and (max-width: 1370px) {
			margin-bottom: 0;
		}
	}
}
