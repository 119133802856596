.chef-search {

	/*
	.navbar {
		top: -18px;

		.navbar-brand {
			img {
				width: 280px;
				height: auto;

				@media screen and (max-width: 440px) {
					width: 200px;
				}
				@media screen and (max-width: 275px) {
					width: 150px;
				}
			}
		}
	}
	header.sticky  {
		.navbar-brand img {
		    padding-top: 28px;
		}
		.navbar-nav {
			margin-top: 10px;
		}
	}
	@media screen and (min-width: 992px) {
		header .navbar-nav > li {
		    margin-top: 35px;
		}
	}
	*/
	.search-content {
		font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;
		background-color: #e1e1e1;
		width: 100%;

		.filters {
			background-color: #ebebeb;
			padding: 22px 10px;

			.filter-title {
				@include gradient();
				height: 40px;
				border-radius: 20px;
				padding: 7px 15px 0px 15px;
				text-align: center;

				p {
					font-size: 18px;
					color: white;
				}
			}

			.filter-box {
				background-color: #a1a1a1;
				color: #fff;
				margin-top: 10px;
				border-radius: 20px;
				height: auto;
				padding: 7px 15px 10px 15px;

				p {
					font-size: 17px;
					margin-bottom: 0;
					color: #fff;

					&.sub {
						font-size: 15px;
						font-family: 'Gibson-Regular', sans-serif;
					}
				}

				.dropdown-filters {
					margin-top: 15px;
					font-family: 'Gibson-Regular', sans-serif;
					text-align: center;

					p {
						margin-top: 7px;
					}

					select {
						background: url('../../assets/img/dropdown-arrow.png') no-repeat 88% #fff;
						background-size: 12px;
						background-color: white;
						height: 40px;
						width: 100%;
						border: none;
						border-radius: 20px;
						color: #666;
						font-size: 15px;
						padding-left: 10px;
					}
				}

				.date-selector {
					margin-top: 15px;

					input {
					    background-color: white;
					    width: 100%;
					    height: 40px;
					    border-radius: 30px;
					    border: none;
					    padding-left: 20px;
					    color: #666;
					}

					span{
						position: relative;
						display: block;

						&:after {
							font-family: 'FontAwesome';
							content: "\f133";
							color: $brand-pink;
							position: absolute;
							right: 18px;
							top: 6px;
							font-size: 18px;
							z-index: 1;
							pointer-events: none;
						}
					}

					button {
						height: 40px;
						background-color: white;
						color: #666;
						font-size: 15px;
						text-align: left;
						i {
							float: right;
							color: $brand-pink;
							font-size: 20px;
						}
					}
				}
			}
		}
		.search-results {
			padding: 15px 0px;

			h4 {
				color: $brand-pink;
				font-size: 24px;
				margin-top: 0;
			}

			.close-chefs {
				background-color: white;
				height: 60px;
				border-radius: 30px;
				padding: 10px;
				margin-bottom: 20px;

				p {
					color: #666666;
					font-size: 15px;
					float: left;
					margin-top: 7px;
					margin-left: 20px;
					.amount {
						color: $brand-pink;
					}
				}

				i.fa {
					float: right;
					font-size: 25px;
					color: #666666;
					margin: 5px 10px 0px 0px;
				}

				.sort-by {
					float: right;

					select {
						font-family: 'Gibson-Regular', sans-serif;
						background: url('../../assets/img/dropdown-arrow.png') no-repeat 90% #fff;
						background-size: 12px;
						background-color: #e1e1e1;
						color: #666;
						font-size: 18px;
						padding-left: 16px;
						padding-right: 40px;
						height: 40px;
						width: auto;
						border: none;
						border-radius: 20px;
					}
				}

				@media screen and (max-width: 710px) {
					height: auto;
					text-align: center;

					i.fa {
						display: none;
					}

					p {
						float: none;
						margin-left: 0;
					}

					.sort-by {
						float: none;

						select {
							display: block;
							margin: 0 auto;
						}
					}
				}
			}
		}

		select {
			cursor: pointer;
			-moz-appearance: none;
			-webkit-appearance: none;

			&:focus {
				outline: none;
			}

			&::-ms-expand {
				display: none;
			}
		}

		.chef-card {
			background-color: #d1d1d1;
			min-height: 300px;
			border-radius: 30px;
			padding: 10px;
			margin-bottom: 25px;

			.col-sm-5 {
				padding-left: 0;
			}

			.chef-info {
				padding-left: 10px;

				.chef-name {
					margin: 10px 0px 0px 0px;
					text-transform: lowercase;

					a {

						color: $brand-pink;
					}
				}
				.distance {
					font-size: 12px;
					color: #666666;
					margin-top: 0;
				}
				.chef-details {
					margin-top: 20px;

					.available {
						width: 100%;
						.available-detail {

							margin-bottom: 10px;

							@media screen and (min-width: $screen-md) {
								margin-left: 58px;
							}

							p {
								margin: 0;
								font-size: 15px;
								font-family: 'Gibson-Regular', sans-serif;
								color: #666666;

								&.larger, span {
									font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;
								}
							}
						}
					}
					.rate {
						width: 100%;

						.rate-detail {
							float: left;
							padding-left: 18px;

							p {
								margin: 0;
								font-size: 15px;
								color: #666666;

								&.sub {
									font-family: 'Gibson-Regular', sans-serif;
								}
							}
						}
					}

					@media screen and (max-width: $screen-lg) {
						.available .available-detail, .rate .rate-detail {
							padding-left: 0px;
							margin-left: 0px;
						}

						.icon-bubble {
							display: none;
						}
					}
					@media screen and (max-width: $screen-md) {
						.available .available-detail, .rate .rate-detail {
							text-align: center;
							float: none;
						}
					}
				}

				@media screen and (max-width: $screen-md) {
					padding-left: 0;
				}
			}
			@media screen and (max-width: $screen-md) {
				height: auto;
				text-align: center;

				.col-sm-5 {
					padding-left: 10px;
				}

				.chef-image {
					width: 100%;
				}
			}
			@media screen and (max-width: $screen-sm) {
				.chef-image {
					width: 200px;
					margin: 0 auto;
				}
			}
		}
	}
}

.chef-image {
	background-position: center;
	background-repeat: no-repeat;
	background-color: #a1a1a1;
	background-size: cover;
	width: 100%;
	height: 270px;
	border-radius: 20px;
	margin-bottom: 10px;
	position: relative;

	.experience {
		position: absolute;
		bottom: -5px;
		left: 50%;
		transform: translateX(-50%);
		background-color: white;
		height: auto;
		width: 120px;
		border-radius: 20px;
		text-align: center;

		p {
			margin: 0;
			font-size: 18px;
			color: $brand-pink;
			height: 20px;

			&.sub {
				color: #a1a1a1;
				height: 25px;
			}
		}
	}
}
.star-rating {
	.stars {
		i {
			font-size: 30px;
		}
	}
	p.amount-of-reviews {
		font-size: 12px;
		margin: 0;
		color: #a1a1a1;
		a {
			color: #a1a1a1;
		}
	}
}

.chef-profile {
	.page-wrapper {
		font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;
		background-color: #e1e1e1;
		.title-area {
			h2 {
				color: $brand-pink;
				font-size: 24px;
				text-transform: lowercase;
			}
		}
		h4.name {
			font-size: 30px;
			color: $brand-pink;
			margin-bottom: 0;
			text-transform: lowercase;
		}
		p.location {
			font-size: 24px;
			color: #a1a1a1;
			text-transform: lowercase;
		}

		.chef-image-wrapper {
			background-color: #d1d1d1;
			padding: 10px 10px 1px 10px;
			border-radius: 30px;
			margin-bottom: 10px;

			.chef-image {
				width: 100%;
				height: 260px;
			}
		}

		.map-area {
			background-color: #d1d1d1;
			padding: 10px;
			border-radius: 30px !important;
			height: 280px;

			.wrapper {
				width: 100%;
				height: 260px;
				border-radius: 30px;
				overflow: hidden;
				-webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC); /* this fixes the overflow:hidden in Chrome/Opera */
			}

			.map {
				background-size: cover;
				background-position: center;
				border-radius: 30px !important;
				width: 100%;
				height: 260px;

				* {
					border-radius: 30px !important;
				}
			}
		}

		.shift-count {
			margin: 15px 0 0 0;

			p {
				color: $light-grey;
				font-size: 14px;
				margin-bottom: 3px;
			}
		}

		.travel {
			margin: 15px 0px 20px 0px;

			p {
				color: $brand-pink;
				font-size: 20px;
			}

			p:first-child {
				margin-bottom: 0;
				color: #a1a1a1;
			}
		}

		.cv-download {
			width: 80%;

			@media screen and (max-width: $screen-lg) {
				width: 100%;
			}

			@media screen and (max-width: $screen-md) {
				margin-bottom: 10px;
			}
		}

		.skills, .certificates {
			font-size: 20px;
			p.lead {
				color: #a1a1a1;
				margin-bottom: 0;
			}
			ul.list {
				padding-left: 0;
				li {
					list-style-type: none;
					color: #9f1b67;
					a {
						color: #9f1b67;
					}
				}
			}
		}
		.calendar-area {
			img {
				width: 100%;
				height: auto;
			}
		}
		.keys {
			background-color: white;
			width: 100%;
			height: auto;
			margin-top: 10px;
			padding: 7px 15px 0px 15px;

			p {
				font-size: 15px;
				font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
				color: #a1a1a1;
				float: left;

				&.first {
					font-family: 'Gibson-SemiBold','Gibson-Regular', sans-serif;

				}
			}

			.key {
				float: left;

				div {
					float: left;
					height: 25px;
					width: 25px;
					border-radius: 50%;
					border: 1px solid #a5a5a5;
					margin-right: 15px;
					margin-left: 50px;
					&.grey {
						background-color: #e1e1e1;
					}
					&.orange {
						background-color: #ffa800;
					}
					&.red {
						background-color: #9e0b0f;
					}
					&.green {
						@include gradient(#8cc63f, #65ad05);
					}
					&.pink {
						@include gradient();
					}
				}
			}

			@media screen and (max-width: $screen-md) {
				p {
					&.first {
						float: none;
					}
				}
			}
		}
		.request {
			background-color: #d1d1d1;
			border-radius: 60px;
			width: 100%;
			height: 60px;
			margin-bottom: 10px;
			padding: 10px;
			.icon-bubble {
				margin-right: 10px;
			}
			.when {
				min-width: 110px;
			}
			.when-text {
				margin-top: 3px;
				p {
					font-size: 16px;
					color: #666;
					line-height: 18px;
					span {
						font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
					}
				}
			}
			.from {
				margin-left: 30px;
				.from-text {
					p {
						font-size: 16px;
						color: #666;
						span {
							font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
						}
					}
				}
				@media screen and (max-width: $screen-lg) {
					margin-left: 15px;
				}
			}
			.details {
				select {
					background: url('../../assets/img/dropdown-arrow.png') no-repeat 85% #fff;
					background-size: 12px;
					background-color: white;
					font-family: 'Gibson-Regular', 'Gibson-SemiBold', sans-serif;
					height: 40px;
					width: 120px;
					border: none;
					border-radius: 20px;
					color: #666;
					font-size: 15px;
					padding-left: 15px;
				}

				//HIDE DEFAULT ARROW
				select::-ms-expand {	display: none; }
				select{
				    -webkit-appearance: none;
				    appearance: none;
				}
			}
			.decision {
				button {
					width: 120px;
					height: 40px;
					text-align: left;
					color: white;
					font-family: 'Gibson-SemiBold', 'Gibson-Regular', sans-serif;
					transition: all .5s;
					img {
						float: right;
						height: 15px;
						width: 15px;
						margin-top: 3px;
					}
					&.btn-accept {
						@include gradient(#8dc63e, #6aae0d);
						img {
							width: 20px;
						}
						&:hover {
							@include gradientReverse(#6aae0d, #8dc63e);
						}
					}
					&.btn-decline {
						@include gradient(#f01616, #d12525);
						margin: 0px 10px 0px 10px;

						&:hover {
							@include gradientReverse(#d12525, #f01616);
						}
					}
				}

				//pending job requests
				&.final {
					p {
						font-size: 16px;
						color: #666;
						margin: 7px 10px 0px 20px
					}
				}
			}
			@media screen and (max-width: $screen-md) {
				height: auto;

				.where-when {
					width: 295px;
					margin: 0 auto;
				}
				.details-wrap {
					width: 400px;
					margin: 10px auto 0px auto;
				}
				.details-wrap, .where-when, .decision.final, .details.final  {
					float: none !important;
				}
				.details.final {
					width: 120px;
					margin: 0 auto;
				}
				.decision.final {
					text-align: center;
				}
			}
			@media screen and (max-width: 500px) {
				.details-wrap {
					width: 120px;
					margin: 0 auto;
				}
				.btn-accept, .btn-decline {
					margin: 10px 0px 0px 0px !important;
				}
				.when, .from, .decision, .details {
					float: none !important;
				}
				.from, .when {
					margin-left: 50%;
					margin-bottom: 10px;
					transform: translateX(-50%);
				}
			}
		}
	}
}
