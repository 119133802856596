@mixin gradient($a: $brand-pink, $b: #961b80) {
	background: $a;
	background: -moz-linear-gradient(left,  $a 0%, $b 100%);
	background: -webkit-linear-gradient(left,  $a 0%, $b 100%);
	background: linear-gradient(to right,  $a 0%, $b 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$a', endColorstr='$b', GradientType=1 );
}
@mixin gradientReverse($a: #961b80, $b: $brand-pink) {
	background: $a;
	background: -moz-linear-gradient(left,  $a 0%, $b 100%);
	background: -webkit-linear-gradient(left,  $a 0%, $b 100%);
	background: linear-gradient(to right,  $a 0%, $b 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$a', endColorstr='$b', GradientType=1 );
}
